import React, {useEffect, useState} from "react";
import {useOfferwall} from "../context/offerwall_context";
import styled from "styled-components";
import {Offer} from "../offerwall/types";
import {OfferBrowserProxy} from "../network";
import {GetOfferBtnUpd, GetOfferButton, Instructions, OfferAction, OfferDescription, OfferName} from "../offerwall/offer_card/styles";
import assets from "../assets";
import OffersContainer from "../offerwall/offers/OffersContainer";

const OffersListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const OfferBox = styled.div`
  border-radius: 10px;
  background: #252a2e;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: space-between;
  width: 100%;
  img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
    margin: 10px;
  }
`;

const OfferNameCompleted = styled(OfferName)`
  white-space: unset;
  font-weight: 400;
`;

const OfferDetails = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

const NoOffersPlaceholder = styled.div`
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
  color: #666;
`;

const OfferNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #9ca0b0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const OfferPrice = styled.div`
  color: #f1f7fa;
  text-align: right;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  & span {
    font-size: 14px;
  }
`;

const OffersList = () => {
  const [completedOffers, setCompletedOffers] = useState<Offer[]>([]);

  const {setOffers, categories, setCategories, activeCategory, setActiveCategory, isLoading, setIsLoading, isOffersLoading, setIsOffersLoading} =
    useOfferwall();

  const getOffers = async (clid: string, category: string | undefined): Promise<Offer[]> => {
    try {
      const offers: Offer[] = await OfferBrowserProxy.getInstance().getTopOffers(clid, category);
      return offers.sort((a, b) => (b.payout || 0) - (a.payout || 0));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsOffersLoading(true);
        //@ts-ignore
        const clid: string = await window.santa.client.getClid();
        const offers: Offer[] = await getOffers(clid, "All");
        setCompletedOffers(offers.filter(offer => offer.status === "COMPLETED"));
        setOffers(offers.filter(offer => offer.status !== "COMPLETED").slice(0, 3));
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => setIsOffersLoading(false), 200);
      }
    })();
  }, []);

  return (
    <>
      <OffersListBox>
        {completedOffers.length === 0 ? (
          <>
            <NoOffersPlaceholder>
              No offers completed yet.
              <br />
              Maybe try this?
              <br />
              <div> </div>
            </NoOffersPlaceholder>
            <OffersContainer />
          </>
        ) : (
          completedOffers.map((offer, index) => (
            <OfferBox key={index}>
              <OfferDetails>
                <img
                  src={offer.imageUrl}
                  alt="offer"
                />
                <Instructions>
                  <OfferNameCompleted>{offer.title}</OfferNameCompleted>
                  <OfferDescription>{offer.description}</OfferDescription>
                  <OfferNumber>
                    <p>dd.mm.yy hh:mm</p>
                    <OfferPrice>
                      ${Number(offer.payout).toFixed(2).split(".")[0]}
                      <span>{Number(offer.payout).toFixed(2).split(".")[1]}</span>
                    </OfferPrice>
                  </OfferNumber>
                </Instructions>
              </OfferDetails>
            </OfferBox>
          ))
        )}
      </OffersListBox>
    </>
  );
};

export default OffersList;

/* <OfferBox>
                <OfferDetails>
                    <img src={assets.header_block} alt="offer" />
                    <Instructions>
                        <OfferNameCompleted>Offer</OfferNameCompleted>
                        <OfferDescription>Here is description of the offer, thank you!</OfferDescription>
                        <OfferNumber>
                            <p>19.08.24 15:47</p>
                            <OfferPrice>$79<span>.99</span></OfferPrice>
                        </OfferNumber>
                    </Instructions>
                </OfferDetails>
    </OfferBox> */

//   useEffect(() => {
//     (async () => {
//       try {
//         if (completedOffers.length <= 0) {
//           //   setOffers(completedOffers);
//         }
//       } catch (e) {
//         console.log(e);
//       } finally {
//       }
//     })();
//   }, [completedOffers]);
