import React, {useCallback, useEffect, useRef, useState} from "react";
import {OfferwallContainer as StyledOfferwallContainer, Body, OffersConty} from "./styles";
import {Offer, Task} from "../types";
import {OfferBrowserProxy} from "../../network";
import {useOfferwall} from "../../context/offerwall_context";
import Banner from "../banner/banner";
import Categories, {Container, ScrollBlock} from "../categories/categories";
import OffersContainer from "./OffersContainer";
import SkeletonLoader from "../../common/loader/SkeletonLoader";
import {ParseQuery} from "../../util/query";

const OfferwallContainer = () => {
  const {
    offers,
    setOffers,
    categories,
    setCategories,
    activeCategory,
    setActiveCategory,
    isLoading,
    setIsLoading,
    isOffersLoading,
    setIsOffersLoading,
  } = useOfferwall();

  // let [searchParams, setSearchParams] = useSearchParams();
  let [categoryBorder, setCategoryBorder] = useState(false);

  const handleBodyScroll = (event: any) => {
    const scrollProgress = event.currentTarget.scrollTop / event.currentTarget.scrollHeight;
    setCategoryBorder(scrollProgress > 0);
  };

  const getOffers = async (clid: string, category: string | undefined): Promise<Offer[]> => {
    try {
      const offers: Offer[] = await OfferBrowserProxy.getInstance().getTopOffers(clid, category);
      return offers.sort((a, b) => (b.payout || 0) - (a.payout || 0));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getCategories = async (): Promise<string[]> => {
    try {
      return await OfferBrowserProxy.getInstance().getOfferCategories();
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        let offerCatrgories: string[] = await getCategories(); // ["All", "Game", "Android", "Survey", "Signup"]
        if (offerCatrgories.length === 0) {
          offerCatrgories = ["All"];
        }

        setCategories(offerCatrgories);
        setActiveCategory(offerCatrgories[0]);
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => setIsLoading(false), 200);
        // setIsLoading(false);
        // setTimeout(() => setIsLoading(false), 100);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsOffersLoading(true);

        const queryParams = ParseQuery(window.location.search);
        console.log("useEffect | queryParams", queryParams);

        //@ts-ignore
        const clid: string = await window.santa.client.getClid();
        const offers: Offer[] = await getOffers(clid, activeCategory);
        // setOffers(offers);
        const notCompletedOffers = offers.filter(offer => offer.status !== "COMPLETED");
        const tasks: Task[] = await OfferBrowserProxy.getInstance().getTasks("clid");
        tasks.forEach(task => {
          let findOffer = notCompletedOffers.find(
            offer => offer.offerId == task.offerId && offer.providerId == task.providerId && offer.status != "COMPLETED"
          );
          if (findOffer) {
            findOffer.status = "IN_PROGRESS";
          }
        });

        setOffers(notCompletedOffers);
        console.log("useEffect | notCompletedOffers", notCompletedOffers);
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => setIsOffersLoading(false), 200);
        // setTimeout(() => setIsOffersLoading(false), 2000);
        // setTimeout(() => setIsLoading(false), 100);
      }
    })();
  }, [activeCategory]);

  const bannerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<HTMLDivElement>(null);
  const [didTouch, setDidTouch] = useState(false);
  useEffect(() => {
    const bodyElement = bodyRef.current;
    if (!bodyElement) return;
    const threshold = 10;
    const handleScroll = () => {
      if (!bannerRef.current || !contentRef.current) return;
  
      const bannerBottom = bannerRef.current.getBoundingClientRect().bottom + bodyElement.scrollTop;
      const offersTop = contentRef.current.getBoundingClientRect().top + bodyElement.scrollTop;
      // If the banner bottom is greater or equal to offers top, it means they are touching or overlapping
      const distance = offersTop - bannerBottom; // Calculate the distance between the elements

      // Only change state if elements are touching or if they are not touching by more than the threshold
      if (distance <= 0) {
        if (!didTouch) { // Prevent unnecessary state updates
          setDidTouch(true);
          // Apply the styles for when elements are touching
          contentRef.current.style.position = 'relative';
          contentRef.current.style.top = '0';
          contentRef.current.style.height = `calc(100vh - ${bannerRef.current.offsetHeight}px)`;
          contentRef.current.style.overflow = `hidden`;
        }
      } else if (distance > threshold) { // Use threshold to prevent glitching
        if (didTouch) { // Prevent unnecessary state updates
          setDidTouch(false);
          // Reset styles to normal state
          contentRef.current.style.position = 'relative';
          contentRef.current.style.top = '';
          contentRef.current.style.marginTop = '';
          contentRef.current.style.height = '';
          contentRef.current.style.overflow = '';
        }
      }
    };
  
    bodyElement.addEventListener('scroll', handleScroll);
  
    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [didTouch]);
  return (
    <React.Fragment>
      <StyledOfferwallContainer ref={bodyRef}>
          <Banner ref={bannerRef}/>
          {(isLoading || categories.length === 0 || offers.length === 0 ) ? (
            <OffersConty ref={contentRef}>
              <SkeletonLoader
                count={4}
                type="categories"
              />
              <SkeletonLoader
                count={50}
                type="offers"
              />
            </OffersConty>
          ) : (
            <OffersConty ref={contentRef}>
              <Categories ref={categoryRef}/>
              <OffersContainer isFixed={didTouch} height={didTouch
                ? `calc(100vh - ${bannerRef.current?.offsetHeight}px - ${categoryRef.current?.offsetHeight}px)`
                : `calc(100vh - var(--banner-height) - ${categoryRef.current?.offsetHeight}px)`}/>
            </OffersConty>
          )}
      </StyledOfferwallContainer>
    </React.Fragment>
  );
};

export default OfferwallContainer;

// function getTasks(arg0: string) {
//   throw new Error("Function not implemented.");
// }
/* {!isLoading && activeCategory === "All" && offers.length && (
    <TrendingSection />
  )} */
