import styled, {keyframes} from "styled-components";
import { useOfferwall } from "../../context/offerwall_context";
import { forwardRef } from "react";

export const Container = styled.div`
  padding: 20px 20px 10px 20px; 
  background: inherit;
  display: flex;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  z-index: 122;
  overflow: hidden;
  background: #1A1E21;
  &::after{
    pointer-events: none;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: rgb(26,30,33);
    background: radial-gradient(circle, rgba(26,30,33,0) 75%, rgba(26,30,33,1) 90%);
  }
`;


export const ScrollBlock = styled.div`
    width: 100%;
    background: #1A1E21;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    position: relative;
    padding-left: 12px
    // background: #1A1E21;
`;

export const CategoryChip = styled.div`
  margin: 0;
  padding: 8px 15px;
  border-radius: 36px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
`;

export default forwardRef<HTMLDivElement> ((props, ref) => {
  const { categories, activeCategory, setActiveCategory, isDark } = useOfferwall();

  return (
    <Container ref={ref}>
      <ScrollBlock>
        {categories.map((category, key) => (
          <CategoryChip
            key={key}
            style={{
              backgroundColor:
                activeCategory === category
                  ? "#212528"
                  : "inherit",
              color:
                activeCategory === category
                  ? "#FFFFFF"
                  : "#ffffff99",
              padding:
                activeCategory === category
                  ? "8px 15px"
                  : "8px 10px"
            }}
            onClick={() => setActiveCategory(category)}>
            {category}
          </CategoryChip>
        ))}
      </ScrollBlock>
    </Container>
  );
});
