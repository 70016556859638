import React, {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import App from "./app";

function initialize() {
  const container = document.getElementById("root")!;
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

document.addEventListener("DOMContentLoaded", initialize);
