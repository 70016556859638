//@ts-nocheck
import {v4 as uuidv4} from "uuid";
import {Quest, RewardProgress} from "./quests/types";
import {Offer, Task} from "./offerwall/types";
import axios from "axios";
import {API_SERVER} from "./util/constants";
import {loadFromStorage, saveToStorage} from "./util/storage";

export class OfferBrowserProxy {
  async getTopOffers(clid: string, category: string | undefined = undefined): Promise<Offer[]> {
    let url: string = `${API_SERVER}/api/offerwalls/${clid}`;
    if (category) {
      url += `/${category.replaceAll(" ", "")}`;
    }
    const res = await axios.get(url);
    const response = res.data.offers;
    return response;
  }

  async getOfferCategories(): Promise<string[]> {
    const res = await axios.get(`${API_SERVER}/api/offerwalls/categories`);
    return res.data;
  }

  async getQuests(): Promise<Quest[]> {
    const res = await axios.get(`${API_SERVER}/api/quests`);
    const response = res.data;
    return response;
  }

  async getRewardProgress(clid: string): Promise<RewardProgress> {
    const res = await axios.get(`${API_SERVER}/api/users/${clid}/rewards/progress`);
    const response = res.data;
    return response;
  }

  async handleClickOffer(clid, body): Promise<void> {
    try {
      console.log("handleClickOffer | body", body);
      // await axios.post(`${API_SERVER}/api/user/${clid}/rewards/initiate`, body, {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      // TODO: temporary crutch
      const tasksState: Task[] = loadFromStorage()["tasks"] as Task[];
      // let newTaskState: Task[] = tasksState.filter((task: Task) => task.offerId != body["offer_id"] && task.providerId != body["provider_id"]);
      let newTaskState: Task[] = tasksState.filter((task: Task) => task.offerId != body["offer_id"]);
      newTaskState.push({
        id: uuidv4(),
        offerId: body["offer_id"],
        offerType: body["offer_type"],
        providerId: body["provider_id"],
        status: "IN_PROGRESS",
      });
      saveToStorage({tasks: newTaskState});
      console.log("tasksState", loadFromStorage()["tasks"] as Task[]);
      //
    } catch (e) {
      console.log(e);
    }
  }

  async handleCompleteOffer(clid, body): Promise<void> {
    try {
      await axios.post(`${API_SERVER}/api/user/${clid}/rewards/complete`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getReferralLink(clid: string): Promise<string> {
    try {
      return `${API_SERVER}/ref/${clid}`;
    } catch (e) {
      console.error("Error getting referral link:", e);
      throw e;
    }
  }

  async getTasks(clid: string): Promise<Task[]> {
    let tasks: Task[] = [];
    const tasksState: Task[] = loadFromStorage()["tasks"] as Task[];
    return tasksState;

    // for (let inProgressOfferId of offersState.inProgress) {
    //   tasks.push({
    //     id: uuidv4(),
    //     status: "IN_PROGRESS",
    //     offerId: inProgressOfferId,
    //   });
    // }

    // for (let completedOfferId of offersState.completed) {
    //   tasks.push({
    //     id: uuidv4(),
    //     status: "COMPLETED",
    //     offerId: completedOfferId,
    //   });
    // }

    return tasks;
    // TODO: implement the server part
    // let url: string = `${API_SERVER}/api/tasks/${clid}`;
    // const res = await axios.get(url);
    // const response = res.data.tasks;
    // return response;
  }

  private isMobileOS() {
    return (
      typeof window !== "undefined" &&
      typeof window.navigator !== "undefined" &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
    );
  }

  static getInstance(): OfferBrowserProxy {
    return instance || (instance = new OfferBrowserProxy());
  }

  static setInstance(obj: OfferBrowserProxy) {
    instance = obj;
  }
}

let instance: OfferBrowserProxy | null = null;
