import styled from "styled-components";
import {useOfferwall} from "../context/offerwall_context";
import React, {useEffect} from "react";

const Container = styled.div`
  position: fixed;
  background: #1e1f24;
  color: #9c9c9c;
  border-radius: 5px;
  left: 0;
  right: 0;
  bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.04);
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 269px;
  z-index: 1;
  opacity: 0.8;
`;

export default () => {
  const {notification, setNotification} = useOfferwall();

  useEffect(() => {
    setTimeout(() => {
      setNotification({
        show: false,
        message: "",
        status: 200,
      });
    }, 5000);
  }, []);

  return <>{notification.show && <Container>{notification.message}</Container>}</>;
};
