import React, {useEffect, useRef, useState} from "react";
import {QuestSanta, QuestSantaTop, QuestsTitle, QuestsSantaTitle, QuestsWrap, QuestBox, QuestTitle, QuestNumber, QuestSantaText} from "../styles";
import assets from "../../assets";
import {QuestSantaProps} from "../types";

const QuestsSanta: React.FC<QuestSantaProps> = ({groups, handleQuestBoxClick}) => {
  const questsWrapRef = useRef<HTMLDivElement>(null);

  const [leftArrowOpacity, setLeftArrowOpacity] = useState(0.5);
  const [rightArrowOpacity, setRightArrowOpacity] = useState(0.5);

  // Array of background styles
  const backgroundStyles = [
    {
      color: "radial-gradient(186.29% 136.93% at 121.81% 101.03%, #F8A11F 0%, #16585D 100%)",

      image: assets.questImg2,
    },
    {
      color: "linear-gradient(83deg, #B13030 4.26%, #4B1414 132.44%)",
      image: assets.questImg1,
    },
    {
      color: "linear-gradient(45deg, #1E3C72 0%, #2A5298 51%, #1E3C72 100%)",
      image: assets.questImg1,
    },
    {
      color: "linear-gradient(45deg, #556270 0%, #FF6B6B 100%)",
      image: assets.questImg2,
    },
  ];
  useEffect(() => {
    const checkScroll = () => {
      if (!questsWrapRef.current) return;
      const {scrollLeft, scrollWidth, clientWidth} = questsWrapRef.current;
      setLeftArrowOpacity(scrollLeft > 0 ? 1 : 0.5);
      setRightArrowOpacity(scrollLeft < scrollWidth - clientWidth ? 1 : 0.5);
    };

    checkScroll();

    const resizeObserver = new ResizeObserver(checkScroll);
    if (questsWrapRef.current) {
      resizeObserver.observe(questsWrapRef.current);
    }

    return () => {
      if (questsWrapRef.current) {
        resizeObserver.unobserve(questsWrapRef.current);
      }
    };
  }, []);
  const checkScroll = () => {
    if (!questsWrapRef.current) return;
    const {scrollLeft, scrollWidth, clientWidth} = questsWrapRef.current;
    setLeftArrowOpacity(scrollLeft > 0 ? 1 : 0.5);
    setRightArrowOpacity(scrollLeft < scrollWidth - clientWidth ? 1 : 0.5);
  };

  return (
    <QuestSanta>
      <QuestSantaTop>
        <div>
          <QuestsTitle>Earn More</QuestsTitle>
          <QuestsSantaTitle>Santa Quests</QuestsSantaTitle>
        </div>
        <div style={{display: "flex", alignItems: "center", gap: "7px"}}>
          <img
            src={assets.leftArr}
            alt="left arrow"
            width={24}
            height={24}
            onClick={() => questsWrapRef.current?.scrollBy({left: -200, behavior: "smooth"})}
            style={{opacity: leftArrowOpacity}}
          />
          <img
            src={assets.rightArr}
            alt="right arrow"
            width={24}
            height={24}
            onClick={() => questsWrapRef.current?.scrollBy({left: 200, behavior: "smooth"})}
            style={{opacity: rightArrowOpacity}}
          />
          {/* <QuestSantaText>View All</QuestSantaText> */}
        </div>
      </QuestSantaTop>

      <QuestsWrap
        ref={questsWrapRef}
        onScroll={checkScroll}>
        {groups.map((group, index) => (
          <QuestBox
            key={group.id}
            style={{background: backgroundStyles[index % backgroundStyles.length].color}}
            onClick={() => handleQuestBoxClick(group)}>
            <QuestTitle>{group.title}</QuestTitle>
            <QuestNumber>{group.quests.length} campaigns</QuestNumber>
            <img
              src={backgroundStyles[index % backgroundStyles.length].image}
              alt="quest image"
              width={109}
              height={109}
              style={{position: "absolute", top: "-15px", right: "-50px", objectFit: "contain", zIndex: "1"}}
            />
          </QuestBox>
        ))}
      </QuestsWrap>
    </QuestSanta>
  );
};

export default QuestsSanta;
