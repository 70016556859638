import React, {useContext, useEffect, useState} from "react";
import {Offer} from "../offerwall/types";
import {Quest, QuestUsage, RewardProgress} from "../quests/types";
import {OfferBrowserProxy} from "../network";

interface NotificationInterface {
  show: boolean;
  message: string;
  status: number;
}

interface OfferwallContextInterface {
  openFilter: () => void;
  is_filters_open: boolean;
  keyword: string;
  devices: string[];
  sort: string;
  setKeyword: (keyword: string) => void;
  setDevices: (devices: string[]) => void;
  setSort: (sort: string) => void;
  notification: NotificationInterface;
  setNotification: (notification: NotificationInterface) => void;
  offers: Offer[];
  setOffers: (offers: Offer[]) => void;
  categories: string[];
  setCategories: (categories: string[]) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isOffersLoading: boolean,
  setIsOffersLoading: (isOffersLoading: boolean) => void;
  activeCategory: string;
  setActiveCategory: (category: string) => void;
  selectedOffer: Offer | undefined;
  setSelectedOffer: (offer: Offer | undefined) => void;
  quests: Quest[];
  setQuests: (offers: Quest[]) => void;
  selectedQuest: Quest | undefined;
  setSelectedQuest: (quest: Quest | undefined) => void;
  questUsage: QuestUsage | undefined;
  setQuestUsage: (questUsage: QuestUsage | undefined) => void;
  rewardProgress: RewardProgress | undefined;
  setRewardProgress: (progress: RewardProgress | undefined) => void;
  clid: string;
  setClid: (clid: string) => void;
  isDark: boolean;
}

const OfferwallContext = React.createContext<OfferwallContextInterface | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const OfferwallProvider: React.FC<Props> = ({children}) => {
  const [show_filters, setShowFilters] = React.useState<boolean>(false);
  const [keyword, setKeyword] = React.useState("");
  const [devices, setDevices] = React.useState<string[]>([]);
  const [sort, setSort] = React.useState("highest_rewards");
  const [notification, setNotification] = React.useState<NotificationInterface>({
    show: false,
    message: "",
    status: 200,
  });
  const [offers, setOffers] = useState<Offer[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("All");
  const [isLoading, setIsLoading] = useState(false);
  const [isOffersLoading, setIsOffersLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<Offer>();
  const [quests, setQuests] = useState<Quest[]>([]);
  const [selectedQuest, setSelectedQuest] = useState<Quest>();
  const [questUsage, setQuestUsage] = useState<QuestUsage>();
  const [rewardProgress, setRewardProgress] = useState<RewardProgress>();
  const [clid, setClid] = useState<string>("");
  const [isDark, setIsDark] = React.useState(window.matchMedia("(prefers-color-scheme: dark)").matches);

  React.useEffect(() => {
    const handleThemeChange = ({matches}: any) => {
      setIsDark(matches);
    };

    matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleThemeChange);

    return () => {
      matchMedia("(prefers-color-scheme: dark)").removeEventListener("click", handleThemeChange);
    };
  }, []);

  const toggleFilters = () => {
    setShowFilters(!show_filters);
  };

  useEffect(() => {
    const getClid = async () => {
      //@ts-ignore
      const clid: string = await window.santa.client.getClid();
      setClid(clid);
    };
    getClid();
  }, []);

  useEffect(() => {
    if (!clid || clid === "") return;

    OfferBrowserProxy.getInstance()
      .getRewardProgress(clid)
      .then((progress: RewardProgress) => {
        setRewardProgress(progress);
      });
  }, [clid]);

  return (
    <OfferwallContext.Provider
      value={{
        openFilter: toggleFilters,
        is_filters_open: show_filters,
        keyword,
        devices,
        sort,
        setKeyword,
        setDevices,
        setSort,
        notification,
        setNotification,
        offers,
        setOffers,
        categories,
        setCategories,
        isLoading,
        setIsLoading,
        activeCategory,
        setActiveCategory,
        selectedOffer,
        setSelectedOffer,
        quests,
        setQuests,
        selectedQuest,
        setSelectedQuest,
        questUsage,
        setQuestUsage,
        clid,
        setClid,
        rewardProgress,
        setRewardProgress,
        isDark,
        isOffersLoading,
        setIsOffersLoading,
      }}>
      {children}
    </OfferwallContext.Provider>
  );
};

export function useOfferwall() {
  const context = React.useContext(OfferwallContext);
  if (context === undefined) {
    throw new Error("useOfferwall must be used within an OfferwallProvider");
  }
  return context;
}
