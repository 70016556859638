import styled from "styled-components";

export const QuestsContainer = styled.div`
  height: 100%;
  background-color: #181818;
  padding: 20px 15px 15px 15px;
  font-family: "Poppins";
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-style: normal;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const QuestsTop = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const QuestsTitle = styled.div`
  color: #f5f5f5;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;

export const QuestsEarningsDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const QuestsEarningCardDiv = styled.div`
  position: relative;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  background: #262626;
  padding: 20px 10px;
  width: 100%;
  padding-bottom: 25px;
  overflow: hidden;
`;
export const CardTitle = styled.p`
  max-width: 100px;
  color: #d44646;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  span {
    color: #f5f5f5;
  }
`;
export const CardPoint = styled.p`
  color: #f5f5f5;
  font-size: 22px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 15px;
  span {
    color: #d44646;
    font-size: 14px;
    font-weight: 400;
  }
`;
export const CardButton = styled.button`
  border: none;
  display: inline-flex;
  padding: 0.625em 3.5em;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ca4343;
  min-width: 50px;
  width: 100%;
  box-sizing: border-box;

  color: #fff;
  font-size: 0.875em;
  font-weight: 600;
  line-height: 13px;
  margin-bottom: 6px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`;
export const CardText = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding-bottom: 7px;
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  span {
    font-weight: 600;
  }
`;

export const QuestsReferralBox = styled.div`
  border-radius: 8px;
  border: 1px solid #ffbe05;
  background: #262626;
`;

export const ReferralTop = styled.div`
  display: flex;
  padding: 0 12px 12px 19px;
  justify-content: space-between;
  img {
    margin-top: 19px;
  }
`;

export const ReferralText = styled.p`
  max-width: 160px;
  color: #ffbe05;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 12px;
  span {
    display: block;
    color: #f5f5f5;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const ReferralPoints = styled.p`
  padding-top: 19px;
  color: #ffbe05;
  font-size: 22px;
  font-weight: 600;
  line-height: 16px;
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ReferralBottom = styled.div`
  color: #ffbe05;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  padding: 5px;
  background: #2c2c2c;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const QuestSanta = styled.div``;

export const QuestSantaTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuestsSantaTitle = styled.p`
  color: #cdcdcd;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
`;

export const QuestSantaText = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #2c2c2c;
  color: #f5f5f5;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  overflow: hidden;
`;

export const QuestsWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
  margin-top: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const QuestBox = styled.div`
  width: 175px;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
  height: 156px;
  background-color: #333;
  padding: 20px 15px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
`;

export const QuestTitle = styled.p`
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
`;

export const QuestNumber = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
`;

export const ModalBackdrop = styled.div<{modalVisible: boolean}>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => (props.modalVisible ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)")};
  display: flex;
  align-items: end;
  z-index: 1000;
  transition: background-color 300ms ease-in-out;
`;

export const ModalContent = styled.div<{modalVisible: boolean}>`
  width: 100%;
  position: relative;
  bottom: 0;
  height: 80vh;
  display: flex;
  padding: 30px 15px 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px 16px 0px 0px;
  background: #262626;
  box-shadow: 0px -16px 14px 8px rgba(0, 0, 0, 0.15);
  transform: ${props => (props.modalVisible ? "translateY(0)" : "translateY(100vh)")};
  transition: transform 300ms ease-in-out;
`;

export const GroupTitle = styled.h1`
  color: rgba(255, 255, 255, 0.7);
  font-size: 28px;
  font-weight: 500;
  line-height: 31px;
`;

export const QuestItem = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 3px solid #252525;
  background: #2c2c2c;
`;

export const QuestReward = styled.div`
  width: 80px;
  display: flex;
  padding: 8px 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid #7a7a7a;
  background: #181818;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 100% */
`;

export const QuestItemTitle = styled.h4`
  color: #ffbe05;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const QuestDesc = styled.p`
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const QuestCompleted = styled.p<{completed: boolean}>`
  color: ${props => (props.completed ? "#72b569" : "#29B6F6")};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: ${props => (props.completed ? "unset" : "underline")};
  pointer-events: ${props => (props.completed ? "none" : "auto")};
`;

export const QuestMain = styled.div`
  display: flex;
  overflow: auto;
  gap: 15px;
`;

export const QuestMainItem = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(24, 24, 24, 0.7);
  background: #262626;
  flex-shrink: 0;
  overflow: hidden;
  flex: 1;
`;

export const QuestMainAction = styled(QuestCompleted)`
  text-decoration-line: unset;
  display: flex;
  gap: 5px;
  position: relative;
  z-index: 2;
  align-items: center;
`;

export const QuestCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media only screen and (max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const QuestCardItem = styled.div`
  min-width: 140px;
  height: 200px;
  position: relative;
  display: flex;
  padding: 10px 10px 40px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #2c2c2c;
`;

export const QuestCardSocial = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #505050;
  background: #2c2c2c;
  padding: 9px 8px;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
  }
`;

export const QuestCardLink = styled.p`
  color: #29b6f6;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  padding-bottom: 14px;
`;

export const SpecificQuestReward = styled(QuestReward)`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
`;

export const SpecificQuestTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 14px; /* 50% */
  text-transform: capitalize;
`;

export const QuestsWorld = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const QuestWorldBanner = styled.div`
  height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WorldBannerDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  min-height: 37.5px;
  width: 230px;
  border-radius: 12px;
  border: 1px solid #60a5fa;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  padding: 8px 12px 8px 8px;
`;

export const WorldBannerText = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    display: block;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const QuestWorldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const QuestWorldItem = styled.div`
  display: flex;
  height: 125px;
  border-radius: 8px;
  background: #2c2c2c;
`;

export const QuestWorldDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px;
`;

export const WorldTextTop = styled.p`
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
`;

export const WorldTextBottom = styled.p`
  color: #b9b9b9;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

export const QuestWorldPoint = styled.p`
  position: absolute;
  top: 10px;
  border-radius: 4px;
  border: 1.5px solid #ffbe05;
  background: #181818;
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 10px;
  span {
    color: #ffbe05;
    padding-right: 3px;
  }
`;

export const LedgerTitle = styled.h1`
  color: #d44646;
  font-size: 21px;
  font-weight: 600;
  line-height: 20px;
`;

export const LedgerSubTitle = styled.h4`
  color: #f5f5f5;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
`;

export const LedgerMonth = styled.p`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px; /* 175% */
`;

export const LedgerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const LedgerItem = styled.div`
  padding: 6px;
  display: flex;
  width: 100%;
  height: 45px;
  border-radius: 7px;
  background: #2c2c2c;
  align-items: center;
  justify-content: space-between;
`;

export const LedgerDate = styled.p`
  width: 80px;
  color: #f5f5f5;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`;

export const LedgerDetails = styled.p`
  width: 50px;
  color: #f5f5f5;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
`;

export const LedgerPrice = styled.p`
  color: #f5f5f5;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
`;
export const LedgerName = styled.p`
  color: #d44646;
  font-size: 9px;
  font-weight: 400;
  line-height: 10px;
`;
