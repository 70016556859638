import React from "react";
import {QuestCardItem, QuestReward, QuestSantaText, QuestCardSocial, QuestDesc, QuestCardLink, QuestCards} from "../styles";
import {Group} from "../types";

interface QuestCardData {
  key: string;
  reward: string;
  category: string;
  description: string;
  campaignsCount: number;
  imageUrl: string;
  actionKey: string;
  showSocialIcon: boolean;
}

interface QuestCardsProps {
  groups: Group[];
  handleSpecificQuestSelection: (key: string) => void;
}

const calculateRewardsAndCount = (groups: Group[], keyword: string) => {
  const filteredQuests = groups.flatMap(group => group.quests).filter(quest => quest.uni.includes(keyword));
  const totalRewards = filteredQuests.map(quest => parseInt(quest.reward, 10)).reduce((acc, reward) => acc + reward, 0);
  const campaignsCount = filteredQuests.length;
  return {totalRewards: `${totalRewards}pts`, campaignsCount};
};

const QuestsCards: React.FC<QuestCardsProps> = ({groups, handleSpecificQuestSelection}) => {
  const twitterData = calculateRewardsAndCount(groups, "twitter");
  const discordData = calculateRewardsAndCount(groups, "discord");
  const defaultData = calculateRewardsAndCount(groups, "default");
  const questCardData: QuestCardData[] = [
    {
      key: "twitter",
      reward: twitterData.totalRewards,
      category: "Social",
      description: "Hey follow us on Twitter for the latest updates & features",
      campaignsCount: twitterData.campaignsCount,
      imageUrl: `https://www.google.com/s2/favicons?domain=twitter.com&sz=128`,
      actionKey: "twitter",
      showSocialIcon: true,
    },
    {
      key: "discord",
      reward: discordData.totalRewards,
      category: "Social",
      description: "Bug Bounty is here! Find Bugs share and earn more points",
      campaignsCount: discordData.campaignsCount,
      imageUrl: `https://www.google.com/s2/favicons?domain=discord.com&sz=128`,
      actionKey: "discord",
      showSocialIcon: true,
    },
  ];
  return (
    <QuestCards>
      {questCardData.map(card => (
        <QuestCardItem key={card.key}>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <QuestReward>{card.reward}</QuestReward>
            <QuestSantaText>{card.category}</QuestSantaText>
          </div>

          {card.showSocialIcon && (
            <QuestCardSocial>
              <img
                src={card.imageUrl}
                alt={`${card.key} icon`}
                width={20}
                height={20}
                style={{borderRadius: "50%"}}
              />
              <p>{card.key.charAt(0).toUpperCase() + card.key.slice(1)} Quests</p>
            </QuestCardSocial>
          )}
          <div style={{position: "absolute", bottom: "0", display: "flex", flexDirection: "column", gap: "5px", paddingRight: "10px"}}>
            <QuestDesc>{card.description}</QuestDesc>

            <QuestCardLink onClick={() => handleSpecificQuestSelection(card.actionKey)}>
              {/* {card.campaignsCount} Campaigns {">"} */}
              More
            </QuestCardLink>
          </div>
        </QuestCardItem>
      ))}
    </QuestCards>
  );
};

export default QuestsCards;
