import styled from "styled-components";
import assets from "../../assets";
import {getClid, getMonthlyRewards, getRewards} from "../../rewards/api";
import React, {forwardRef, useEffect, useState} from "react";

export const Container = styled.div<{$isBgImageLoaded?: boolean}>`
  position: sticky;
  top: 0;
  overflow: hidden;
  background: ${({$isBgImageLoaded}) => ($isBgImageLoaded ? `url(${assets.header_block})` : "#712121")};
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 18px 0px 18px;
  z-index: 5;
  width: 100%;
`;

export const CompleteOffersText = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #ffffff;
  line-height: normal;
  text-align: right;
  top: 30px;
  right: 20px;
  span {
    display: block;
    position: relative;
    right: -30px;
  }
`;

export const OffersTitleBox = styled.div`
  padding-top: 18px;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const OffersX = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
`;
export const OffersTitle = styled.h1`
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`;

export const OffersProgressBox = styled.div`
  position: absolute;
  bottom: 55px;
  right: 20px;
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  span {
    display: block;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 20px;
  }
`;

const FadeInImage = styled.img<{opacity: number}>`
  position: absolute;
  top: 70px;
  left: -20px;
  height: 250px;
  width: 200px;
  object-fit: contain;
  transition: opacity 0.5s ease;
  opacity: ${({opacity}) => opacity};
`;

export default forwardRef<HTMLDivElement> ((props, ref) => {
  const [isBgImageLoaded, setBgImageLoaded] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [points, setPoints] = useState<string>("0");
  const [pointsFraction, setPointsFraction] = useState<string>("00");

  const handleBgImageLoad = () => {
    setBgImageLoaded(true);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    (async () => {
      const bgImage = new Image();
      bgImage.src = assets.header_block;
      bgImage.onload = handleBgImageLoad;

      const image = new Image();
      image.src = assets.santa_happy;
      image.onload = handleImageLoad;

      try {
        const res = await getRewards(await getClid());
        if (res.aggregated_rewards.length > 0) {
          const sp: string = res.aggregated_rewards[0].sp;
          const splitedSP = sp.split(".");
          if (splitedSP.length > 1) {
            setPoints(splitedSP[0]);
            setPointsFraction(splitedSP[1].slice(0, 2));
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  const handleXBtnClick = async () => {
    //@ts-ignore
    await window.santa.client.activateTab("chrome://mobile-ntp");
  };
  useEffect(() => {
    const updateBannerHeight = () => {
      const bannerElement = document.querySelector('#bannerId');
      if (bannerElement instanceof HTMLElement) { // Checks if bannerElement is not null
        const bannerHeight = bannerElement.offsetHeight;
        document.documentElement.style.setProperty('--banner-height', `${bannerHeight}px`);
      }
    };
  
    // Call it on mount and you might want to call this function on window resize if the banner height is responsive
    updateBannerHeight();
  
    // Optionally, you can listen to resize events to update the height dynamically if it changes
    window.addEventListener('resize', updateBannerHeight);
  
    // Clean up event listener to prevent memory leaks
    return () => window.removeEventListener('resize', updateBannerHeight);
  }, []);
  return (
      <Container id="bannerId" $isBgImageLoaded={isBgImageLoaded}>
        <OffersTitleBox ref={ref}>
          <OffersX onClick={handleXBtnClick}>
            <img
              src={assets.xBtn}
              alt="X Button"
            />
          </OffersX>
          <OffersTitle>Santa Offers</OffersTitle>
        </OffersTitleBox>
        {/* <FadeInImage
          src={assets.santa_happy}
          alt="happy santa"
          opacity={isImageLoaded ? 1 : 0}
        />
        <CompleteOffersText>
          Complete tasks <span>Collect rewards!</span>
        </CompleteOffersText>
        <OffersProgressBox>
          <p>
            ${points}.{pointsFraction}
            <span>Your progress</span>
          </p>
        </OffersProgressBox> */}
      </Container>
  );
});
