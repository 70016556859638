// /path/to/SkeletonLoader.tsx
import React from "react";
import styled, { keyframes } from "styled-components";
import { CategoryChip, Container, ScrollBlock } from "../../offerwall/categories/categories";
import { AllOffersContainer } from "../../offerwall/offers/styles";
import { StyledOfferCard } from "../../offerwall/offer_card/styles";


interface SkeletonLoaderProps {
  count: number;
  type: "categories" | "offers";
}


const pulse = keyframes`
  0% {
    background-color: #1a1e21;
  }
  50% {
    background-color: #2a2e31;
  }
  100% {
    background-color: #1a1e21;
  }
`;

const AnimatedCategoryChip = styled(CategoryChip)`
  width: 70px;
  height: 36px;
  background-color: #212528;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const AnimatedOfferChip = styled(StyledOfferCard)`
  width: 110px;
  height: 120px;
  background-color: #212528;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ count, type }) => {
  switch (type) {
    case "categories":
      return (
        <Container>
          <ScrollBlock style={{ gap: '10px' }}>
            {[...Array(count)].map((_, i) => (
              <AnimatedCategoryChip key={i}></AnimatedCategoryChip>
            ))}
          </ScrollBlock>
        </Container>
      );
    case "offers":
      return (
        <AllOffersContainer>
          {[...Array(count)].map((_, i) => (
            <AnimatedOfferChip key={i} />
          ))}
        </AllOffersContainer>
      );
    default:
      return null;
  }
};

export default SkeletonLoader;
