import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {fetchQuests} from "../quests/api";
import {Group, Quest} from "../quests/types";
import {API_SERVER} from "../util/constants";

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const provider = queryParams.get("provider");
  const [message, setMessage] = useState(`Handling ${provider} Authentication... Please wait.`);
  const [clid, setClid] = useState<string | null>(null);
  const [quest, setQuest] = useState<Quest | null>(null);
  const [isLinked, setIsLinked] = useState<boolean>(false); // Track if the linking was successful
  const [isError, setIsError] = useState<boolean>(false); // Track if there was an error

  useEffect(() => {
    const fetchClid = async () => {
      try {
        //@ts-ignore
        const userClid = await window.santa.client.getClid();
        setClid(userClid);
      } catch (error) {
        setIsError(true);
        console.error("Failed to fetch clid:", error);
      }
    };
    fetchClid();
  }, []);
  useEffect(() => {
    const initializeData = async () => {
      try {
        const groups = await fetchQuests(clid);

        let foundQuest: Quest | undefined = undefined;
        const questUni = provider === "twitter" ? "twitterAuth" : "discordAuth";
        for (const group of groups) {
          foundQuest = group.quests.find(q => q.uni === questUni);
          if (foundQuest) break;
        }

        if (foundQuest) {
          setQuest(foundQuest);
        } else {
          throw new Error("Quest not found");
        }
      } catch (error: any) {
        console.error("Error initializing data:", error.message);
        console.error("Error stack trace:", error.stack);
        setIsError(true);
      }
    };
    if (clid) {
      initializeData();
    }
  }, [clid]);
  useEffect(() => {
    const completeAuthProcess = async () => {
      if (clid && quest) {
        try {
          await axios.post(`${API_SERVER}/api/quests/complete`, {
            userClid: clid,
            questId: quest?.id,
          });
          setMessage(`Yeah! You've linked your ${provider} account.`);
          setIsLinked(true);
        } catch (error) {
          console.error("Failed to complete authentication:", error);
          setMessage(`Failed to link your ${provider} account. Please try again.`);
          setIsError(true);
        }
      }
    };

    if (clid && quest) {
      completeAuthProcess();
    }
  }, [provider, navigate, clid, quest]);
  const determinePlatform = (): string => {
    // This could check the window.location or some configuration to determine the platform
    return window.location.host.includes("mobile") ? "mobile" : "desktop";
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#181818",
        color: "#FFFFFF",
        flexDirection: "column",
        textAlign: "center",
      }}>
      <h1>{message}</h1>
      {isLinked ? (
        <button
          style={{padding: "10px 20px", fontSize: "16px", cursor: "pointer", marginTop: "20px"}}
          onClick={() => navigate("/quests")}>
          Go Back to Quests
        </button>
      ) : (
        isError && (
          <button
            style={{padding: "10px 20px", fontSize: "16px", cursor: "pointer", marginTop: "20px"}}
            onClick={() => navigate("/quests")}>
            Try Again
          </button>
        )
      )}
    </div>
  );
};

export default Callback;
