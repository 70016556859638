import styled from "styled-components";
import Header from "./Header";
import EarningSummary from "./EarningSummary";
import { useEffect } from "react";
import { getClid, getMonthlyRewards, getRewards } from "./api";
import { useRewardContext } from "./RewardContext";
import assets from "../assets";
import Footer from "../common/footer/footer";
import Categories from "./Categories";
import CategoryList from "./CategoryList";
import OffersList from "./OffersList";

export const MainCont = styled.div`
  background-color: #1a1e21;
  color: #ffffff;
  padding-bottom: 5vh;
  min-height: 100vh;
`;
export const Container = styled.div`
  position: relative;
  padding: 18px 18px 10px 18px;
  overflow: hidden;
  background-image: url(${assets.header_block});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100%;
  background-size: cover;
`;

export const Heading = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 15px;
  margin-top: 25px;
`;

const FooterCont = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const Body = styled.div``;

const Rewards = () => {
  const { setRewardData } = useRewardContext();

  useEffect(() => {
    const loadData = async () => {
      try {
        // const res = await getMonthlyRewards(await getClid());
        // const res = await getRewards(await getClid());
        // console.log("res", res)

        // if (res !== null) setRewardData(res);
      } catch (e) {
        console.log(e);
      }
    };
    loadData();
  }, []);

  return (
    <MainCont>
      <Container>
        <Header />
        <EarningSummary />
      </Container>
      <Heading>Completed Offers</Heading>
      {/* <Categories showBorder={false} /> */}
      {/* <CategoryList /> */}
      <OffersList />
      <FooterCont>
        <Footer />
      </FooterCont>
    </MainCont>
  );
};

export default Rewards;
