import styled from "styled-components";
import assets from "../assets";
import Reward from "./Reward";
import {getClid, getMonthlyRewards, getRewards} from "./api";
import {useRewardContext} from "./RewardContext";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  padding-top: 15px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500px;
  color: "#FFFFFF";
`;

const RewardTitle = styled.div`
  color: #9c9c9c;
  font-size: 12px;
  line-height: 16px;
`;

const RewardsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: rgba(28, 28, 28, 0.2);
  backdrop-filter: blur(2px);
  padding: 15px 19px;
`;

const NoRewardsDiv = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const PriceDiv = styled.div`
  & label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

const PriceLabel = styled.div`
  display: flex;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
  align-items: center;
  & span {
    font-size: 16px;
    font-weight: 500;
    padding-left: 2px;
  }
`;

const PriceLabelCoin = styled.div`
  width: 23px;
  height: 23px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  & img {
    width: 14px;
    height: 14px;
  }
`;

const EarnMore = styled.button`
  border-radius: 16px;
  padding: 5px 13px;
  background-color: #fff;
  border: none;
  color: #b13030;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
`;

const NoRewardsTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600px;
  text-align: center;
`;

const RedeemBtn = styled.button`
  border-radius: 11px;
  background: transparent;
  text-align: center;
  padding: 12px 16px;
  border-radius: 11px;
  border: 2px solid ${({disabled}) => (disabled ? "#B3B3B3" : "#4CAF50")};
  color: ${({disabled}) => (disabled ? "#B3B3B3" : "#4CAF50")};
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  font-style: normal;
  opacity: ${({disabled}) => (disabled ? "0.6" : "1")};
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    border-color: ${({disabled}) => (disabled ? "#B3B3B3" : "#4CAF50")};
    color: ${({disabled}) => (disabled ? "#B3B3B3" : "#4CAF50")};
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  & span {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
`;

const NoRewardsSubTitle = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #9c9c9c;
`;

export default () => {
  const {rewardData} = useRewardContext();
  const [points, setPoints] = useState<string>("0");
  const [pointsFraction, setPointsFraction] = useState<string>("00");
  const [iou_redeemable, setIouRedeemable] = useState<string>("0");
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await getRewards(await getClid());
        console.log("res", res);
        setIouRedeemable(res.iou_earned);
        // if (res.aggregated_rewards.length > 0) {
        //   const sp: string = res.aggregated_rewards[0].sp;
        //   const splitedSP = sp.split(".");
        //   if (splitedSP.length > 1) {
        //     setPoints(splitedSP[0]);
        //     setPointsFraction( splitedSP[1].slice(0,2));
        //   }
        // }
      } catch (e) {
        console.log(e);
      }
    };
    loadData();
  }, []);

  const handleEarnMoreClick = (path: string) => {
    navigate(path);
  };
  return (
    <Container>
      <RewardsDiv>
        <PriceDiv>
          <label>Offers</label>
          <PriceLabel>
            {/* ${rewardData.sp_redeemable} */}${points}
            <span>.{pointsFraction}</span>
          </PriceLabel>
        </PriceDiv>
        <EarnMore onClick={() => handleEarnMoreClick("/offers")}>
          <img
            src={assets.cashback_dollar_red}
            alt={""}
          />
          Earn more
        </EarnMore>
      </RewardsDiv>
      <RewardsDiv>
        <PriceDiv>
          <label>Quests</label>
          <PriceLabel>
            <PriceLabelCoin>
              <img
                src={assets.santa_hat}
                alt="santa hat"
              />
            </PriceLabelCoin>
            {iou_redeemable} <span>IOU</span>
          </PriceLabel>
        </PriceDiv>
        <EarnMore onClick={() => handleEarnMoreClick("/quests")}>
          <img
            src={assets.quests_diamond_red}
            alt={""}
          />
          Earn more
        </EarnMore>
      </RewardsDiv>
      {/* <RewardsDiv>
        <PriceDiv>
          <label>Cashback</label>
          <PriceLabel>
            ${rewardData.cashback_redeemable}
            <span>.00</span>
          </PriceLabel>
        </PriceDiv>
        <EarnMore>
          <img src={assets.cashback_dollar_red} alt={""} />
          Earn more
        </EarnMore>
      </RewardsDiv> */}
      <ButtonDiv>
        <RedeemBtn disabled>Claim</RedeemBtn>
      </ButtonDiv>
    </Container>
  );
};
