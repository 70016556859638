import React from "react";
import {QuestsEarningsProps} from "../types";
import assets from "../../assets";
import {CardButton, CardPoint, CardText, CardTitle, QuestsEarningCardDiv, QuestsEarningsDiv} from "../styles";
import axios from "axios";
import {API_SERVER} from "../../util/constants";

const QuestsEarningCard: React.FC<{
  title: string;
  amount: number;
  threshold: number;
  onClick?: () => void;
  isPoints?: boolean;
}> = ({title, amount, threshold, onClick, isPoints}) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);
  const handleContainerClick = (e: any) => {
    e.stopPropagation(); // Prevent clicks from bubbling to other elements
    toggleModal();
  };
  const handleClaim = async (e: any) => {
    e.stopPropagation();
    try {
      //@ts-ignore
      const clid = await window.santa.client.getClid();
      console.log(clid);
      const endpoint = isPoints ? `/claim_points/${clid}` : `/claim_rewards/${clid}`;

      const response = await axios.post(`${API_SERVER}${endpoint}`);
      console.log("Response:", response.data);
      alert(`Successfully claimed ${isPoints ? "points" : "rewards"}! Check your wallet`);
    } catch (error: any) {
      console.error("Error when claiming rewards/points:", error);
      alert(`Failed to claim ${isPoints ? "points" : "rewards"}: ${error.message}`);
    }
  };
  const isEligible = amount >= threshold;

  return (
    <QuestsEarningCardDiv
      // onClick={onClick}
      style={{position: "relative"}}>
      <CardTitle>
        {title} <span>Earned</span>
      </CardTitle>
      <div style={{zIndex: "2"}}>
        <CardPoint>{isPoints ? `${amount.toLocaleString()} pts` : `$${amount.toLocaleString()}`}</CardPoint>
        {isPoints ? (
          <CardText>
            Min <span>{threshold.toLocaleString()} pts</span> to Claim
          </CardText>
        ) : (
          <CardText>
            Min <span>${threshold.toLocaleString()}</span> to Claim
          </CardText>
        )}
        <CardButton
          disabled={!isEligible}
          onClick={() => isEligible && handleClaim}>
          CLAIM
        </CardButton>
      </div>
      {/* <img
      src={assets.right_arrow_gray}
      alt="right arrow gray"
      style={{position: "absolute", right: "15px", top: "20px"}}
    /> */}
      {isPoints && (
        <img
          src={assets.trophy_cup}
          alt="trophy cup"
          width={77}
          height={97}
          style={{position: "absolute", bottom: "0", zIndex: "1", left: "-20px"}}
        />
      )}
    </QuestsEarningCardDiv>
  );
};

const QuestsEarnings: React.FC<QuestsEarningsProps> = ({userRewards, handleLedgerModalClick}) => {
  return (
    <QuestsEarningsDiv>
      <QuestsEarningCard
        title="Shopping & Play Wall"
        amount={userRewards.usd_earned}
        // threshold={userRewards.usd_threshold}
        threshold={20}
        onClick={handleLedgerModalClick}
      />
      <QuestsEarningCard
        title="Total Santa Points"
        amount={userRewards.sp_earned}
        // threshold={userRewards.usd_threshold}
        threshold={500}
        isPoints={true}
      />
    </QuestsEarningsDiv>
  );
};

export default QuestsEarnings;
