import React, {useEffect, useState} from "react";
import {ClaimOfferModalProps} from "./types";
import {
  BackDrop,
  ConfirmAction,
  Container,
  Content,
  ModalActions,
  Price,
  Rewards,
  Title,
  Instructions,
  InstructionDescription,
  OfferUrl,
  HeaderImage,
  HeaderImageBackDrop,
  OfferImage,
  OfferDetails,
  Categories,
  Reward,
  RewardLevel,
  RewardPayout,
} from "./styles";
import assets from "../../assets";
import {OfferBrowserProxy} from "../../network";
import {parseFromString} from "../../util/helpers";

export const ClaimOfferModal = React.forwardRef<HTMLDivElement, ClaimOfferModalProps>((props, ref) => {
  const [clientID, setClientID] = useState("");
  const [gaid, setGaid] = useState("");

  useEffect(() => {
    const getClid = async () => {
      //@ts-ignore
      const clid: string = await window.santa.client.getClid();
      setClientID(clid);
    };
    getClid();

    const getGaid = async () => {
      // @ts-ignore
      const res: string = await window.santa.client.getGaid();
      setGaid(res);
    };
    getGaid();
  }, []);

  // console.log(props.offer?.events);
  let offer_url = props.offer?.offerUrl;

  return (
    <BackDrop onClick={() => props.quit(undefined)}>
      <Container ref={ref}>
        <HeaderImage src={props.offer?.imageUrl ?? ""} />
        <HeaderImageBackDrop />

        <Content>
          <div style={{display: "flex", gap: "15px"}}>
            <OfferImage src={props.offer?.imageUrl ?? ""} />
            <OfferDetails>
              <Title>{props.offer?.title}</Title>
              <Price>${props.offer?.payout?.toFixed(2)}</Price>
              <Categories>{props.offer?.categories?.join(", ")}</Categories>
            </OfferDetails>
          </div>
        </Content>

        <Instructions>
          <span>Description</span>
          <InstructionDescription>{parseFromString(props.offer?.description ?? "")}</InstructionDescription>

          {props.offer?.terms && (
            <>
              <span>Terms</span>
              <InstructionDescription>{parseFromString(props.offer?.terms ?? "")}</InstructionDescription>
            </>
          )}

          {props.offer?.events && (
            <>
              <span>Rewards</span>
              <Rewards>
                {props.offer?.events.map((e, i) => (
                  <div key={i}>
                    <Reward>
                      <RewardPayout>${e.payout.toFixed(2)}</RewardPayout>
                      <RewardLevel>{e.name}</RewardLevel>
                    </Reward>
                  </div>
                ))}
              </Rewards>
            </>
          )}
        </Instructions>

        <ModalActions>
          <ConfirmAction
            onClick={async e => {
              e.stopPropagation();
              await OfferBrowserProxy.getInstance().handleClickOffer(clientID, {
                name: props.offer?.title,
                image: props.offer?.imageUrl,
                points: props.offer?.payout?.toString(),
                offer_id: props.offer?.offerId,
                provider_id: props.offer?.providerId,
                offer_type: "offerwall",
              });
              window.open(offer_url?.replace("{{userId}}", clientID).replace("{{gaid}}", gaid), "_blank");
            }}>
            Earn ${props.offer?.payout?.toFixed(2)}
          </ConfirmAction>
        </ModalActions>
      </Container>
    </BackDrop>
  );
});

ClaimOfferModal.displayName = "ClaimOfferModal";
