import React, {useEffect, useState} from "react";
import { API_SERVER } from "../util/constants";

const Debug: React.FC = () => {
  const [code, setCode] = useState<string>("");
  const [output, setOutput] = useState<string>("");
  const [refLink, setRefLink] = useState<string>("");

  const executeCode = async () => {
    try {
      setOutput("");
      //@ts-ignore
      let result = eval(code);

      // Check if result is a Promise and handle accordingly
      if (result instanceof Promise) {
        result = await result;
      }

      setOutput(JSON.stringify(result, null, 2));
    } catch (error) {
      setOutput(`Error: ${error}`);
    }
  };

  const resetAllUserActions = async () => {
    try {
      await fetch(`${API_SERVER}/api/user/actions/resetAllUserActions`);
    } catch (error) {
      console.log("error")
    }
  };
  useEffect(() => {
    // Simulating fetching the clid
    const fetchClid = async () => {
      // Replace this with your actual method to obtain the clid
      //@ts-ignore
      return await window.santa.client.getClid();
    };

    fetchClid()
      .then(clid => {
        const baseUrl =
          "https://play.google.com/store/apps/details?id=com.santa.web3.browser&referrer=utm_source%3Dtest_source%26utm_medium%3Dtest_medium%26utm_term%3Dtest-term%26utm_content%3Dtest_content%26utm_campaign%3Dtest_namet%26ref%3D";
        const adjustedLink = baseUrl + encodeURIComponent(clid);
        setRefLink(adjustedLink);
      })
      .catch(error => {
        console.error("Error fetching CLID:", error);
        // Set a default or error link if necessary
      });
  }, []);
  return (
    <div style={{color: "#FFFFFF"}}>
      <a
        href="https://play.google.com/store/apps/details?id=com.santa.web3.browser&referrer=utm_source%3Dtest_source%26utm_medium%3Dtest_medium%26utm_term%3Dtest-term%26utm_content%3Dtest_content%26utm_campaign%3Dtest_namet%26ref%3D=123456
">
        store test
      </a>

      <a
        href={refLink}
        style={{display: "block", marginTop: "20px"}}>
        ref link of the current user
      </a>
      <button onClick={resetAllUserActions}>Reset all user actions</button>
      <div style={{marginTop: "20px"}}>Текстфилд:</div>
      <textarea
        value={code}
        onChange={e => setCode(e.target.value)}
        style={{width: "100%", height: "100px"}}
      />
      <button onClick={executeCode}>Send</button>
      <pre>{output}</pre>
    </div>
  );
};

export default Debug;
