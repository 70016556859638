import {removePrefix, splitOnce} from "./text";

function decodeQueryString(str: string) {
  return decodeURIComponent(str.replace(/\+/g, "%20"));
}

export function ParseQuery(queryString: string): Record<string, string> {
  if (!queryString) {
    return {};
  }
  const queryMap = {};
  const pairs = (queryString[0] === "?" ? queryString.slice(1) : queryString).split("&");
  for (let i = 0; i < pairs.length; i++) {
    let [key, value] = pairs[i].split("=");
    Object(queryMap)[decodeQueryString(key)] = decodeQueryString(value || "");
  }
  return queryMap;
}

export function MapToQueryParams(values: Record<string, string>): string {
  return new URLSearchParams(values).toString().replaceAll("+", " ");
}

export function getHashParams(hash?: string): Record<string, string | undefined> {
  return Object.fromEntries(
    removePrefix(hash ?? window.location.hash, "#")
      .split("&")
      .map(i => {
        const keyValue = splitOnce(i, "=");
        if (keyValue.length === 1) {
          return [keyValue.at(0), undefined];
        }
        return keyValue;
      })
  );
}
