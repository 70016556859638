import styled from "styled-components";
import assets from "../../assets";
import { useOfferwall } from "../../context/offerwall_context";
import { getTabs } from "../../util/constants";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRewardContext } from "../../rewards/RewardContext";

const Container = styled.div`
  display: none;
  align-items: center;
  overflow-x: hidden;
  padding: 10px 15px 10px 15px;
  justify-content: space-between;
  gap: 14px;
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: #202528;
  /* @media (prefers-color-scheme: dark) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #202528;
  } */
`;

const CategoryChip = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  color: rgba(73, 73, 73, 0.7);
  text-align: center;
  padding-right: 14px;
  @media (prefers-color-scheme: dark) {
    color: #ffffffb2;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 18px;
  opacity: 0.5;
  background: rgba(37, 36, 36, 0.5);
  @media (prefers-color-scheme: dark) {
    color: #ffffff80;
  }
`;

export default () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { isDark } = useOfferwall();

  return (
    <Container>
      {getTabs().map((tab, key) => (
        <div key={key}>
          <CategoryChip
            style={{
              color:
              location.pathname === tab.route
                ? "#FFFFFF"
                : "#ffffff99"
            }}
            onClick={() => navigate(tab.route)}>
            {tab.text}
          </CategoryChip>

          {/* { && <Divider />} */}
        </div>
      ))}
    </Container>
  );
};
