import React from "react";

export interface AffiliateRewards {
  id: string;
  name: string;
  image: string;
  completed_on: string | null;
  affiliate_tx_id: string;
  points: string;
}

export interface ShoppingRewards {
  id: string;
  name: string;
  image: string;
  completed_on: string | null;
  affiliate_tx_id: string;
  points: string;
}

export interface QuestRewards {
  id: string;
  name: string;
  image: string;
  type: string;
  points: string;
  completed_on: string | null;
  affiliate_tx_id: string;
  "quests.type": string;
}

export interface MonthlyRewards {
  id: string;
  clid: string;
  name: string;
  redeemable_from: string;
  redeem_status: string;
  iou_redeemable: string;
  sp_redeemable: string;
  cashback_redeemable: string;
  affiliate_rewards: AffiliateRewards[];
  shopping_rewards: ShoppingRewards[];
  quest_rewards: QuestRewards[];
}

interface RewardContextInterface {
  rewardData: MonthlyRewards;
  setRewardData: (data: MonthlyRewards) => void;
  activeCategory: string;
  setActiveCategory: (data: string) => void;
}

const RewardContext = React.createContext<RewardContextInterface | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}

export interface RewardDataInterface {
  iou_earned: String;
  iou_redeemable: String;
  sp_earned: String;
  sp_redeemable: String;
  aggregated_rewards: RewardInterface[];
}

export interface RewardInterface {
  id: String;
  clid: String;
  name: String;
  sp: String;
  iou: String;
  redeemable_from: String;
  redeemed_on: String;
  redeem_status: String;
  timestamp: String;
}

export const RewardProvider: React.FC<Props> = ({ children }) => {
  const [rewardData, setRewardData] = React.useState<MonthlyRewards>({
    id: "",
    clid: "",
    name: "",
    redeemable_from: "",
    redeem_status: "",
    iou_redeemable: "0",
    sp_redeemable: "0",
    affiliate_rewards: [],
    shopping_rewards: [],
    quest_rewards: [],
    cashback_redeemable: "0",
  });

  const [activeCategory, setActiveCategory] = React.useState<string>("All");

  return (
    <RewardContext.Provider
      value={{
        rewardData,
        setRewardData,
        activeCategory,
        setActiveCategory,
      }}>
      {children}
    </RewardContext.Provider>
  );
};

export function useRewardContext() {
  const context = React.useContext(RewardContext);
  if (context === undefined) {
    throw new Error("useOfferwall must be used within an OfferwallProvider");
  }
  return context;
}
