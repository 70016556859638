import React, {useEffect, useState} from "react";
import {OfferwallProvider} from "./context/offerwall_context";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Rewards from "./rewards/Rewards";
import {RewardProvider} from "./rewards/RewardContext";
import Marketplace from "./marketplace/Marketplace";
import Notification from "./common/Notification";
import axios from "axios";
import OnlyOnSanta from "./common/OnlyOnSanta";
import OfferwallContainer from "./offerwall/offers/OfferwallContainer";
import styled from "styled-components";
import Quests from "./quests/Quests";
import Debug from "./debug/Debug";
import Callback from "./callback/Callback";
// import Testik from "./testik/Testik";

axios.defaults.baseURL = window.location.origin;
const router = createBrowserRouter([
  {
    path: "/offers",
    element: <OfferwallContainer />,
  },
  {
    path: "/cashback",
    element: <></>,
  },
  {
    path: "/quests",
    element: <Quests />,
  },
  {
    path: "/",
    element: <Rewards />,
  },
  {
    path: "/debug",
    element: <Debug />,
  },
  {
    path: "/auth-callback",
    element: <Callback />,
  },

  // {
  //   path: "/test",
  //   element: <Testik />,
  // },
]);

const App = () => {
  //@ts-ignore
  const [isSanta] = useState<boolean>(!!window.santa);

  if (!isSanta) {
    // @ts-ignore
    window.santa = {
      client: {
        async getClid() {
          return "27df28187e84bdb";
        },
        async getGaid() {
          return "abcdrmgr";
        },
        async isDefaultBrowser() {
          return false;
        },
      },
    };
  }

  //   useEffect(() => {
  //     (async () => {
  //         //@ts-ignore
  //         if (await santa.client.isDarkTheme()) {
  //             document.documentElement.className = "theme-dark";
  //         } else {
  //             document.documentElement.className = "theme-light";
  //         }
  //     })();
  // }, []);

  // if (!isSanta) {
  //   return (
  //     <>
  //       <OnlyOnSanta />
  //     </>
  //   );
  // }

  return (
    <React.Fragment>
      <OfferwallProvider>
        <RewardProvider>
          <Notification />
          <RouterProvider router={router} />
        </RewardProvider>
      </OfferwallProvider>
    </React.Fragment>
  );
};

export default App;
