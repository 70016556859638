import React, {useEffect, useState} from "react";
import {
  ModalBackdrop,
  ModalContent,
  QuestsContainer,
  QuestsReferralBox,
  QuestsTitle,
  QuestsTop,
  ReferralBottom,
  ReferralPoints,
  ReferralText,
  ReferralTop,
} from "./styles";
import assets from "../assets";
import {completeQuest, fetchQuests, fetchUserRewards} from "./api";
import {Group, ModalState, ModalType, Quest, UserRewards} from "./types";
import QuestsEarnings from "./quest_components/QuestsEarnings";
import QuestsSanta from "./quest_components/QuestsSanta";
import QuestsDefaultInstall from "./quest_components/QuestsDefaultInstall";
import QuestsCards from "./quest_components/QuestsCards";
import QuestsWorldComponent from "./quest_components/QuestsWorld";
import LedgerModal from "./quest_components/LedgerModal";
import SelectedGroupModal from "./quest_components/SelectedGroupModal";
import SpecificQuestsModal from "./quest_components/SpecificQuestsModal";
import axios from "axios";
import {API_SERVER} from "../util/constants";

const Quests: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [notification, setNotification] = useState({visible: false, message: ""});
  const [twitterUser, setTwitterUser] = useState<string | null>(null);
  const [userRewards, setUserRewards] = useState<UserRewards>({usd_earned: 0, sp_earned: 0, usd_threshold: 10, sp_threshold: 1000});
  const [attemptedSetDefault, setAttemptedSetDefault] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({type: null});
  const [clid, setClid] = useState<string | null>(null);

  useEffect(() => {
    const fetchClid = async () => {
      try {
        //@ts-ignore
        const userClid = await window.santa.client.getClid();
        setClid(userClid);
      } catch (error) {
        console.error("Failed to fetch clid:", error);
      }
    };

    fetchClid();
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const [groupData, rewardsData] = await Promise.all([fetchQuests(clid), fetchUserRewards(clid)]);
        setGroups(groupData);
        setUserRewards(rewardsData);
        const socialGroup = groupData.find(group => group.category === "social");
        if (socialGroup) {
          checkSocialQuests(socialGroup.quests);
        }
      } catch (error: any) {
        console.error("Error initializing data:", error.message);
        console.error("Error stack trace:", error.stack);
      }
    };

    if (clid) {
      initializeData();
    }
  }, [clid]);

  useEffect(() => {
    const checkDefaultStatus = async () => {
      if (attemptedSetDefault) {
        //@ts-ignore
        const isDefault = await window.santa.client.isDefaultBrowser();
        if (isDefault) {
          const defaultQuest = groups.flatMap(group => group.quests).find(quest => quest.uni === "default-1" && !quest.done);
          if (defaultQuest) {
            //@ts-ignore
            const response = await completeQuest(defaultQuest.id, await window.santa.client.getClid());
            if (response.success) {
              updateQuestStatus(defaultQuest.id, true);
              setNotification({visible: true, message: "Browser set as default successfully!"});
            }
          }
          setAttemptedSetDefault(false);
        }
      }
    };

    const interval = setInterval(checkDefaultStatus, 1000);
    return () => clearInterval(interval);
  }, [attemptedSetDefault, groups]);

  const handleSetDefaultBrowser = async () => {
    //@ts-ignore
    await window.santa.client.setAsDefault();
    setAttemptedSetDefault(true);
  };

  const updateQuestStatus = (questId: string, done: boolean) => {
    setGroups(groups =>
      groups.map(group => ({
        ...group,
        quests: group.quests.map(quest => (quest.id === questId ? {...quest, done} : quest)),
      }))
    );
  };

  const handleQuestAction = async (quest: Quest) => {
    if (quest.uni.includes("referral")) {
      await copyReferralLinkAndNotify();
    } else if (quest.uni.includes("default")) {
      await handleSetDefaultBrowser();
    } else {
      if (quest.link) window.open(quest.link, "_blank");
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      if (modalVisible) {
        closeModal();
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [modalVisible]);

  const showModal = (type: ModalType, data?: any) => {
    window.history.pushState({modal: true}, "Modal");
    setModalState({type, data});
    setTimeout(() => {
      setModalVisible(true);
    }, 10);
  };

  const closeModal = () => {
    if (window.history.state?.modal) {
      window.history.back();
    }
    setModalVisible(false);
    setTimeout(() => {
      setModalState({type: null, data: null});
    }, 300);
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };
  const handleQuestBoxClick = (group: Group) => {
    showModal("groupDetails", group);
  };

  const handleLedgerModalClick = () => {
    showModal("ledger");
  };

  const handleSpecificQuestSelection = (questType: string) => {
    const filteredQuests = groups.flatMap(group => group.quests).filter(quest => quest.uni.includes(questType));
    showModal("specificQuests", filteredQuests);
  };

  const renderModalContent = () => {
    switch (modalState.type) {
      case "ledger":
        return <LedgerModal groups={groups} />;
      case "groupDetails":
        return (
          <SelectedGroupModal
            selectedGroup={modalState.data}
            handleQuestAction={handleQuestAction}
            clid={clid}
            addRewardPoints={addRewardPoints}
          />
        );
      case "specificQuests":
        return (
          <SpecificQuestsModal
            selectedSpecificQuests={modalState.data}
            handleQuestAction={handleQuestAction}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (modalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalVisible]);

  const showAndHideNotification = (message: string) => {
    setNotification({visible: true, message});
    setTimeout(() => {
      setNotification({visible: false, message: ""});
    }, 2000);
  };

  const copyReferralLinkAndNotify = async () => {
    try {
      //@ts-ignore
      const clid = await window.santa.client.getClid();
      console.log("myclid: ", clid);
      const baseUrl =
        "https://play.google.com/store/apps/details?id=com.santa.web3.browser&referrer=utm_source%3Dtest_source%26utm_medium%3Dtest_medium%26utm_term%3Dtest-term%26utm_content%3Dtest_content%26utm_campaign%3Dtest_name%26ref%3D";
      const referralUrl = baseUrl + encodeURIComponent(clid);
      const tempInput = document.createElement("input");
      tempInput.value = referralUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      showAndHideNotification("Link Copied to Clipboard");
    } catch (error) {
      console.error("Error handling referral link:", error);
    }
  };

  const checkSocialQuests = (quests: Quest[]) => {
    quests.forEach(quest => {
      switch (quest.uni) {
        case "discordMember":
          checkDiscordMembership(quest);
          break;
        case "twitterTagging":
          checkTwitterTagging(quest);
          break;
        default:
          break;
      }
    });
  };

  const checkDiscordMembership = async (quest: Quest) => {
    if (!quest.done) {
      try {
        const response = await axios.get(`${API_SERVER}/check-membership`, {withCredentials: true});
        const isUserMember = response.data.isMember;
        if (isUserMember) {
          const result = await completeQuest(quest.id, clid);
          if (result.success) {
            updateQuestStatus(quest.id, true);
          }
        }
      } catch (error) {
        console.error("Failed to check for mention:", error);
      }
    }
  };

  const checkTwitterTagging = async (quest: Quest) => {
    if (!quest.done) {
      try {
        const response = await axios.get(`${API_SERVER}/check-for-mention`);
        const isTagged = response.data.mentionFound;
        if (isTagged) {
          const result = await completeQuest(quest.id, clid);
          if (result.success) {
            updateQuestStatus(quest.id, true);
          }
        }
      } catch (error) {
        console.error("Failed to check for mention:", error);
      }
    }
  };

  const addRewardPoints = (points: number) => {
    setUserRewards(prevRewards => ({
      ...prevRewards,
      sp_earned: prevRewards.sp_earned + points,
    }));
  };

  return (
    <React.Fragment>
      <QuestsContainer>
        <QuestsTop>
          <img
            src={assets.trophy}
            alt="trophy"
            width={40}
            height={40}
          />
          <QuestsTitle>Earnings</QuestsTitle>
        </QuestsTop>

        <QuestsEarnings
          userRewards={userRewards}
          handleLedgerModalClick={handleLedgerModalClick}
        />

        <QuestsReferralBox>
          <ReferralTop>
            <img
              src={assets.referUsers}
              alt="refer users"
              width={35}
              height={35}
              style={{objectFit: "contain"}}
            />
            <ReferralText>
              Refer and Earn
              <span>Refer your friends and earn points</span>
            </ReferralText>

            <ReferralPoints>
              100 <span>pts</span>
            </ReferralPoints>
          </ReferralTop>
          <ReferralBottom onClick={copyReferralLinkAndNotify}>Copy Referral Link</ReferralBottom>
        </QuestsReferralBox>
        <QuestsCards
          groups={groups}
          handleSpecificQuestSelection={handleSpecificQuestSelection}
        />
        <QuestsSanta
          groups={groups}
          handleQuestBoxClick={handleQuestBoxClick}
        />
        <QuestsDefaultInstall
          handleSetDefaultBrowser={handleSetDefaultBrowser}
          groups={groups}
        />

        <QuestsWorldComponent />

        {modalState.type && (
          <ModalBackdrop
            onClick={handleBackdropClick}
            modalVisible={modalVisible}>
            <ModalContent
              onClick={e => e.stopPropagation()}
              modalVisible={modalVisible}>
              {renderModalContent()}
            </ModalContent>
          </ModalBackdrop>
        )}

        {notification.visible && (
          <div
            style={{
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#72b569",
              color: "#f5f5f5",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 1000,
            }}>
            {notification.message}
          </div>
        )}
      </QuestsContainer>
    </React.Fragment>
  );
};

export default Quests;
