import styled from "styled-components";
import assets from "../assets";

export const QuestsX = styled.div`
  position: absolute;
  left: 0;
  // bottom: 0;
  // top: 50%;
  // transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
`;

export const IconDiv = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: inherit;
    height: inherit;
  }
`;
const handleXBtnClick = async () => {
  //@ts-ignore
  await window.santa.client.activateTab("chrome://mobile-ntp");
};
export default () => {
  return (
    <Container>
      <QuestsX onClick={handleXBtnClick}>
        <img
          src={assets.xBtn}
          alt=""></img>
      </QuestsX>
      <Title>Estimated Earnings</Title>
    </Container>
  );
};
