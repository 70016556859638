const initState = {
  tasks: [],
};

// Save state to Storage
export function saveToStorage(state: any) {
  try {
    let serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
}

// load state from Storage
export function loadFromStorage() {
  try {
    let serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      saveToStorage(initState);
      return initState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
}

// reset local storage state
export function resetLocalStorageState() {
  try {
    saveToStorage(initState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
