import React, {useEffect, useState} from "react";
import {Quest, SelectedGroupProps} from "../types";
import {GroupTitle, QuestCompleted, QuestDesc, QuestItem, QuestItemTitle, QuestReward} from "../styles";
import {completeQuest} from "../api";

const SelectedGroupModal: React.FC<SelectedGroupProps> = ({selectedGroup, handleQuestAction, clid, addRewardPoints}) => {
  const [quests, setQuests] = useState(selectedGroup.quests);

  const handleQuestClick = async (quest: Quest) => {
    if (quest.uni === "telegramAuth" && !quest.done) {
      handleTelegramAuth(quest);
    } else {
      await handleQuestAction(quest);
    }
  };

  const handleTelegramAuth = async (quest: Quest) => {
    //@ts-ignore
    window.Telegram.Login.auth({bot_id: "7082897065", request_access: true}, async function (data: any) {
      if (data) {
        if (!quest.done) {
          const result = await completeQuest(quest.id, clid);

          if (result.completed_at) {
            setQuests(prev => {
              return prev.map(q => (q.id === quest.id ? {...q, done: true} : q));
            });
            addRewardPoints(Number(quest.reward));
          } else {
            console.error("Failed to complete quest on server.");
          }
        }
      } else {
        console.error("Telegram authentication failed.");
      }
    });
  };

  useEffect(() => {
    setQuests(selectedGroup.quests);
  }, [selectedGroup.quests]);

  return (
    <>
      <GroupTitle>{selectedGroup.title}</GroupTitle>
      <div style={{overflow: "auto", width: "100%"}}>
        {quests.map(quest => (
          <QuestItem
            key={quest.id}
            className="quest-detail">
            <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
              <QuestReward>{quest.reward} pts</QuestReward>
              <QuestItemTitle>{quest.title}</QuestItemTitle>
            </div>

            <QuestDesc>{quest.description}</QuestDesc>
            <QuestCompleted
              completed={quest.done}
              onClick={() => handleQuestClick(quest)}>
              {quest.done ? "Completed" : "Complete Now"}
            </QuestCompleted>
          </QuestItem>
        ))}
      </div>
    </>
  );
};

export default SelectedGroupModal;
