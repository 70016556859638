import styled from "styled-components";

export const Body = styled.div<{}>`
  overflow: auto;
  // &::-webkit-scrollbar {
  //   width: 0px;
  // }
`;

export const Heading = styled.div`
  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
  letter-spacing: -0.37px;
  margin: 15px 15px 0px 15px;
`;

export const AllOffersContainer = styled.div`
  position: relative;
  padding: 15px 15px 0px 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  width: 100%;
  justify-items: center;
  align-items: center;
  background: #1A1E21;
  padding-top: 40px;
`;

export const TrendingOffersContainer = styled.div`
  padding: 15px 15px 5px 15px;
  overflow-y: hidden;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: 97% 97% 97% 97%;
  gap: 15px;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-padding: 14px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > img {
    width: 30px;
  }
`;

export const OfferwallContainer = styled.div`
  //display: grid;
  height: 100vh;
  overflow: auto;
  //grid-template-rows: auto 1fr auto;
  background-color: #1A1E21;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OffersConty = styled.div<{}>`
  width: 100%;
  background: #1A1E21;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 11;
  position: relative;
`