export const splitArrayIntoChunks = (arr: any[], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
}
  
export const parseFromString = (content: string): string => {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(content, "text/html")

    return document.body.textContent ?? "";
}

export var camalize = function camalize(str:string) {
    return str.replace(/(\w)(\w*)/g,
    function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}