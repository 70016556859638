import React from "react";
import {
  OfferDescription,
  OfferImage,
  Instructions,
  OfferName,
  OfferCategories,
  OfferAction,
  GetOfferButton,
  OfferDetail,
  StyledOfferCardContainer,
  GetOfferBtnUpd,
} from "./styles";
import {OfferCardProps} from "./types";
import styled from "styled-components";
import {parseFromString} from "../../util/helpers";

const StyledOfferCard = styled.div`
  display: grid;
  align-items: start;
  border-radius: 5px !important;
  border: 1px solid #252a2e;
  background: #202528 !important;
  grid-template-rows: auto 1fr;
  border-radius: 5px;
  position: relative;
  background: inherit;
  overflow: hidden;
  top: -30px;
`;

const OfferRewardTitle = styled.div`
  color: #ffffff;
  font-size: 10px;
`;

const OfferCardVertical = React.forwardRef<HTMLDivElement, OfferCardProps>((props, ref) => {
  return (
    <StyledOfferCard
      style={{opacity: props.offer.status !== "ACTIVE" ? 0.4 : 1}}
      onClick={() => {
        if (props.openDetails && props.offer.status === "ACTIVE") props.openDetails(props.offer);
      }}>
      <div style={{padding: "10px 8px", overflow: "hidden"}}>
        <OfferImage src={props.offer.imageUrl} />
        <OfferDetail>
          <Instructions>
            <OfferName>{props.offer.title}</OfferName>
            {/* <OfferCategories>{props.offer.categories?.[0]}</OfferCategories> */}
          </Instructions>
        </OfferDetail>
        <OfferDescription>{parseFromString(props.offer.description ?? "")}</OfferDescription>
        <OfferAction>
          {/* <OfferRewardTitle>Reward:</OfferRewardTitle> */}
          {/* <GetOfferBtnUpd>${(Number(props.offer.payout) || 0).toFixed(2)}</GetOfferBtnUpd> */}
          <GetOfferBtnUpd> {props.offer.status == "IN_PROGRESS" ? "In progress" : (Number(props.offer.payout) || 0).toFixed(2)} </GetOfferBtnUpd>
        </OfferAction>
      </div>
    </StyledOfferCard>
  );
});

OfferCardVertical.displayName = "OfferCardVertical";
export default OfferCardVertical;
