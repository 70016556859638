import styled, { keyframes } from "styled-components";

const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-50%) rotate(30deg);
  }
  100% {
    transform: translateX(150%) rotate(30deg);
  }
`;

export const OfferImage = styled.img`
  height: 80px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

export const StyledOfferCard = styled.div`
  border-radius: 5px;
  position: relative;
  background: #1e1f24;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  font-family: Poppins;
  font-size: 10px;
  line-height: 14px;
`;

export const StyledOfferCardContainer = styled.div`
  display: block;
  position: relative;
  color: inherit;
  transition:
    padding 300ms,
    background-color 300ms,
    box-shadow 300ms,
    border-radius 300ms;
  will-change: padding, box-shadow, border-radius;
  border-radius: 8px;
`;

export const OfferDetail = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
`;

export const OfferName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #fff;
`;

export const OfferCategories = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff99;
`;

export const OfferDescription = styled.div`
  margin-top: 2px;
  text-overflow: ellipsis;
  min-width: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Poppins;
  font-size: 10px;
  line-height: normal;
  color: #ffffff99;
`;

export const OfferAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const GetOfferButton = styled.button`
  display: inline-block;
  padding: 1px 1px;
  background: radial-gradient(circle, #ff3030, #b2102f);
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(255, 0, 0, 0.5);
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border: 2px solid #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: 2px;
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
    transform: rotate(30deg);
    animation: ${shimmerAnimation} 2s infinite linear;
  }
`;


export const GetOfferBtnUpd = styled.button`
  display: inline-flex;
  // padding: 1px 23px 0px 23px;
  width: 97%;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background: #D44646;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(255, 255, 255, 0.12) inset;
  border: none;
  color: #F8F8F8;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`