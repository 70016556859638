import {Group, Quest, CompleteQuestResponse, UserRewards} from "./types";
import {API_SERVER} from "../util/constants"; // Assume these types are defined in types.ts

const BASE_URL = `${API_SERVER}/api`;

export async function fetchQuests(clid: string | null): Promise<Group[]> {
  const response = await fetch(`${BASE_URL}/quests/list?platform=mobile&clid=${clid}`);
  if (!response.ok) {
    throw new Error("Failed to fetch quests");
  }
  return response.json();
}

export async function completeQuest(questId: string, clid: string | null): Promise<CompleteQuestResponse> {
  const response = await fetch(`${BASE_URL}/quests/complete`, {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({userClid: clid, questId}),
  });
  if (!response.ok) {
    throw new Error("Failed to complete quest");
  }
  return response.json();
}

export async function fetchUserRewards(clid: string | null): Promise<UserRewards> {
  const response = await fetch(`${BASE_URL}/users/rewards?clid=${clid}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user rewards");
  }
  return response.json();
}
