import search_icon from "./search_icon.svg";
import filter_icon from "./filter_icon.svg";
import x_circle from "./x_circle.svg";
import x from "./x.svg";
import spinner from "./spinner.svg";
import white_x from "./white_x.svg";
import rewards_icon from "./rewards-icon.svg";
import cup_icon from "./cup.svg";
import gift_icon_red from "./gift-icon-red.svg";
import bg from "./bg.png";
import quests_diamond_red from "./quests_diamond_red.svg";
import cashback_dollar_red from "./cashback_dollar_red.svg";
import ballCh from "./ball.png";
import santa_happy from "./santa-happy.png";
import xBtn from "./Xbtn.png";
import header_block from "./Header_Block.svg";
import santa_hat from "./sr-santahat.png";
import white_tick from "./white_tick.png";
import ticky from "./ticky.svg";
import santaHat from "./santa-hat.svg";
import trophy from "./trophy.png";
import referUsers from "./refer-users.png";
import rightArr from "./cheveron_right.png";
import leftArr from "./cheveron_left.png";
import questImg1 from "./quest-image-1.png";
import questImg2 from "./quest-image-2.png";
import blue_download from "./quest_main_install/blue_download.png";
import quest_main1 from "./quest_main_install/quest_main1.png";
import quest_main2 from "./quest_main_install/quest_main2.png";
import quest_main3 from "./quest_main_install/quest_main3.png";
import quest_main4 from "./quest_main_install/quest_main4.png";
import quest_world_banner from "./world_quests/quest_world_banner.png";
import hourglass from "./world_quests/hourglass.png";
import quest_world_1 from "./world_quests/quest_world_1.png";
import quest_world_2 from "./world_quests/quest_world_2.png";
import quest_world_3 from "./world_quests/quest_world_3.png";
import right_arrow_gray from "./right_arrow_gray.svg";
import trophy_cup from "./trophy_cup.png";
import green_tick from "./green_tick.png";
import back_arrow from "./ledger/back_arrow.png";
import ledger1 from "./ledger/ledger1.png";
import ledger2 from "./ledger/ledger2.png";
import ledger3 from "./ledger/ledger3.png";
export default {
  quests_diamond_red,
  cashback_dollar_red,
  cup_icon,
  gift_icon_red,
  bg,
  search_icon,
  filter_icon,
  x_circle,
  x,
  spinner,
  white_x,
  rewards_icon,
  ballCh,
  santa_happy,
  xBtn,
  header_block,
  santa_hat,
  white_tick,
  ticky,
  santaHat,
  trophy,
  referUsers,
  rightArr,
  leftArr,
  questImg1,
  questImg2,
  blue_download,
  quest_main1,
  quest_main2,
  quest_main3,
  quest_main4,
  quest_world_banner,
  hourglass,
  quest_world_1,
  quest_world_2,
  quest_world_3,
  right_arrow_gray,
  trophy_cup,
  green_tick,
  back_arrow,
  ledger1,
  ledger2,
  ledger3,
};
