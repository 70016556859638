import React from "react";
import {QuestCompleted, QuestDesc, QuestItem, SpecificQuestReward, SpecificQuestTitle} from "../styles";
import {SpecificQuestsProps} from "../types";

type Props = {};

const SpecificQuestsModal: React.FC<SpecificQuestsProps> = ({selectedSpecificQuests, handleQuestAction}) => {
  return (
    <>
      <SpecificQuestTitle>
        <img
          src={`https://www.google.com/s2/favicons?domain=${
            selectedSpecificQuests[0].uni.includes("discord")
              ? "discord"
              : selectedSpecificQuests[0].uni.includes("twitter")
                ? "twitter"
                : selectedSpecificQuests[0].uni.includes("default")
                  ? "default"
                  : "example"
          }.com&sz=128`}
          alt=""
          style={{borderRadius: "50%"}}
          height={40}
          width={40}
        />
        {selectedSpecificQuests[0].uni.includes("discord")
          ? "discord"
          : selectedSpecificQuests[0].uni.includes("twitter")
            ? "twitter"
            : selectedSpecificQuests[0].uni.includes("default")
              ? "default"
              : "example"}{" "}
        Quests
      </SpecificQuestTitle>
      <div style={{overflow: "auto", width: "100%"}}>
        {selectedSpecificQuests.map(quest => (
          <QuestItem
            key={quest.id}
            className="quest-detail">
            <div style={{display: "flex", gap: "10px"}}>
              <SpecificQuestReward>{quest.reward} pts</SpecificQuestReward>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "10px 0"}}>
                <QuestDesc>{quest.description}</QuestDesc>
                <QuestCompleted
                  completed={quest.done}
                  onClick={() => handleQuestAction(quest)}>
                  {quest.done ? "Completed" : "Review here"}
                </QuestCompleted>
              </div>
            </div>
          </QuestItem>
        ))}
      </div>
    </>
  );
};

export default SpecificQuestsModal;
