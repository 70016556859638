import styled from "styled-components";

export const BackDrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  background-color: #101213;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  width: 100%;
  margin: 0 auto;
  margin-top: auto;
`;

export const HeaderImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 125px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  object-fit: cover;
`;

export const HeaderImageBackDrop = styled.div`
  position: absolute;
  top: 0;
  height: 125px;
  width: 100%;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.57);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
`;

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0px 15px;
  transform: translateY(50%);
`;
export const OfferImage = styled.img`
  width: 125px;
  height: 125px;
  border-radius: 21px;
  object-fit: cover;
`;

export const OfferDetails = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
`;

export const Title = styled.div`
  width: 100%;
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Price = styled.div`
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 30px;
  margin-top: 15px;
`;

export const Categories = styled.div`
  color: #ffffff99;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Currency = styled.div`
  color: #fff;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  align-self: flex-start;
`;

export const Tag = styled.div`
  display: none;
`;

export const Rewards = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Reward = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RewardPayout = styled.div`
  color: #29B6F6;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  border-radius: 5px;
  background: rgba(41, 182, 246, 0.12);
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
`;

export const RewardLevel = styled.div`
  color: #ffffff99;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InstructionDescription = styled.p`
  color: #ffffff99;
  margin-top: 3px;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Instructions = styled.div`
  margin-top: 70px;
  padding: 0px 15px;
  & span {
    color: #fff;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ModalActions = styled.div`
  & button {
    border-radius: 8px;
    padding: 10px 0px;
    flex: 1;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px 15px 20px 15px;
`;

export const CancelAction = styled.button`
  background-color: transparent;
  color: #fff;
  border: 1px solid #484b4d;
`;

export const ConfirmAction = styled.button`
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  background: #b13434;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.15),
    0px 4px 4px 0px rgba(255, 255, 255, 0.12) inset;
  color: #f8f8f8;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border: none;
  @media (prefers-color-scheme: dark) {
    /* color: #1b1e21;
    background-color: #fff; */
  }
`;

export const OfferUrl = styled.div`
  word-break: break-all;
  font-size: 10px;
  line-height: 14px;
  margin-top: 10px;
  color: #9c9c9c;
`;
