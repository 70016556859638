export const enum Prefs {
  MostSearchedKeyWords = "santa.most_recent_searched_topics",
  SantaHiddenNewsTileIdList = "santa.hidden_news_tile.id",
  SantaOffers = "santa.offers",
}

export const REWARDS = "Dashboard";
export const OFFERS = "Offers";
export const CASHBACK = "Cashback";
export const QUESTS = "Quests";

export const NOT_STARTED = "NOT_STARTED";
export const ONGOING = "ONGOING";
export const COMPLETED = "COMPLETED";

// Type of quest
export const BROWSER_USAGE = "browser_usage";
export const TAB_USAGE = "tab_usage";
export const WALLET_TX_SENT = "wallet_tx_sent";
export const WALLET_TX_RECEIVED = "wallet_tx_received";
export const OFFERWALL_OPENED = "offerwall_opened";
export const SANTA_BUTTON_TAP = "santa_button_tap";
export const SANTA_BUTTON_DOUBLE_TAP = "santa_button_double_tap";
export const SANTA_BUTTON_LONG_TAP = "santa_button_long_tap";
export const ADBLOCK_USAGE = "adblock_usage";

export const BROWSER_SIDE_QUEST_TYPE= [
  BROWSER_USAGE,
  TAB_USAGE,
  WALLET_TX_SENT,
  WALLET_TX_RECEIVED,
  OFFERWALL_OPENED,
  SANTA_BUTTON_TAP,
  SANTA_BUTTON_DOUBLE_TAP,
  SANTA_BUTTON_LONG_TAP,
  ADBLOCK_USAGE,
];

export const getTabs = () => {
  return [
    {
      route: "/",
      text: REWARDS,
    },
    {
      route: "/offers",
      text: OFFERS,
    },
    {
      route: "/quests",
      text: QUESTS,
    },
  ];
};

export const API_SERVER = process.env.REACT_APP_SERVER_URL || "https://rewards.santabrowser.com"
