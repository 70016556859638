import {API_SERVER} from "../util/constants";

export const getClid = async () => {
  //@ts-ignore
  return await window.santa.client.getClid();
};

export const getRewards = async (clid: string) => {
  try {
    //@ts-ignore
    if (await window.santa.client.isDefaultBrowser()) {
      await fetch(`${API_SERVER}/api/users/${clid}/actions/setDefaultBrowser`);
    } else {
      await fetch(`${API_SERVER}/api/users/${clid}/actions/resetDefaultBrowser`);
    }

    let res = await fetch(`${API_SERVER}/api/users/${clid}/rewards`);
    let rewards = await res.json();
    console.log("!!! rewards", rewards);
    return rewards;

    return {
      iou_earned: "0",
      iou_redeemable: "0",
      sp_earned: "0",
      sp_redeemable: "0",
      aggregated_rewards: [],
    };
  } catch (e) {
    console.log(e);
    return {
      iou_earned: "0",
      iou_redeemable: "0",
      sp_earned: "0",
      sp_redeemable: "0",
      aggregated_rewards: [],
    };
  }
};

export const getMonthlyRewards = async (clid: string, id = "123") => {
  try {
    let res = await fetch(`${API_SERVER}/api/user/${clid}/rewards/${id}`);
    if (res.status === 200) {
      return await res.json();
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const claimReward = async (id: string, clid: string) => {
  try {
    let res = await fetch(`${API_SERVER}/api/user/${clid}/rewards/${id}/claim`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return {
      status: res.status,
      response: (await res.json()).message,
    };
  } catch (e) {
    console.log(e);
    return {
      status: 500,
      response: "Error occurred during the cliam process, Kindly try again",
    };
  }
};
