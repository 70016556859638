import assets from "../../assets";
import {LedgerBox, LedgerDate, LedgerDetails, LedgerItem, LedgerMonth, LedgerName, LedgerPrice, LedgerSubTitle, LedgerTitle} from "../styles";
import {Group} from "../types";

interface LedgerProps {
  groups: Group[];
}

const LedgerModal: React.FC<LedgerProps> = ({groups}) => {
  return (
    <>
      <div style={{display: "flex", gap: "12px"}}>
        <img
          src={assets.back_arrow}
          alt="back arrow"
          width={40}
          height={40}
        />
        <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
          <LedgerTitle>Shopping & Play Wall</LedgerTitle>
          <LedgerSubTitle>Detailed list of rewards and shopping cashback</LedgerSubTitle>
        </div>
      </div>
      <>
        <LedgerMonth>March 2024</LedgerMonth>
        <LedgerBox>
          <LedgerItem>
            <img
              src={assets.ledger1}
              alt="ledger 1"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>20-Mar-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$60.00</LedgerPrice>
              <LedgerName>Cashback</LedgerName>
            </LedgerDetails>
          </LedgerItem>
          <LedgerItem>
            <img
              src={assets.ledger2}
              alt="ledger 2"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>20-Mar-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$60.00</LedgerPrice>
              <LedgerName>Cashback</LedgerName>
            </LedgerDetails>
          </LedgerItem>
          <LedgerItem>
            <img
              src={assets.ledger3}
              alt="ledger 3"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>20-Mar-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$20.00</LedgerPrice>
              <LedgerName>Game Wall</LedgerName>
            </LedgerDetails>
          </LedgerItem>
        </LedgerBox>
      </>
      <>
        <LedgerMonth>February 2024</LedgerMonth>
        <LedgerBox>
          <LedgerItem>
            <img
              src={assets.ledger1}
              alt="ledger 1"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>28-Feb-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$40.00</LedgerPrice>
              <LedgerName>Cashback</LedgerName>
            </LedgerDetails>
          </LedgerItem>
          <LedgerItem>
            <img
              src={assets.ledger2}
              alt="ledger 2"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>15-Feb-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$60.00</LedgerPrice>
              <LedgerName>Cashback</LedgerName>
            </LedgerDetails>
          </LedgerItem>
          <LedgerItem>
            <img
              src={assets.ledger3}
              alt="ledger 3"
              width={74}
              height={32.5}
              style={{objectFit: "contain"}}
            />
            <LedgerDate>01-Feb-2024</LedgerDate>
            <LedgerDetails>
              <LedgerPrice>$20.00</LedgerPrice>
              <LedgerName>Game Wall</LedgerName>
            </LedgerDetails>
          </LedgerItem>
        </LedgerBox>
      </>
    </>
  );
};

export default LedgerModal;
