import React, {useEffect} from "react";

import {AllOffersContainer as StyledOffersContainer, SectionContainer, Heading, SpinnerContainer} from "./styles";
import {Offer} from "../types";
import {useOfferwall} from "../../context/offerwall_context";
import OfferCardVertical from "../offer_card/OfferCardVertical";
import {ClaimOfferModal} from "../claim_offer_modal/ClaimOfferModal";
import assets from "../../assets";
import SkeletonLoader from "../../common/loader/SkeletonLoader";

interface Props {
  isFixed?: boolean;
  height?: string;
}

const OffersContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {offers, selectedOffer, setSelectedOffer, isOffersLoading} = useOfferwall();
  const [current_page, setCurrentPage] = React.useState(0);

  const offerCount = offers.length;
  const offersPerPage = 50;
  const page_count = Math.ceil(offerCount / offersPerPage);
  const offset = current_page * offersPerPage;
  const observer = React.useRef<IntersectionObserver>();

  const last_element_ref = React.useCallback((node: HTMLDivElement) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && current_page !== page_count) {
        setCurrentPage(page => page + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const openModal = React.useCallback(
    (offer: Offer | undefined) => {
      setSelectedOffer(offer);
    },
    [selectedOffer]
  );

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
    // setCurrentPage(0);
    // const newOffers = props.data.slice(0, props.items_per_scroll);
    // setOffers([...newOffers]);
  }, [offers]);

  useEffect(() => {
    // console.log(current_page);
  }, [current_page]);

  return (
    <>
      {isOffersLoading ? (
        <SkeletonLoader count={10} type="offers" />
      ) : (
        <div style={props.isFixed ? {height: `${props.height}`, overflowY: 'scroll'} : {height: `${props.height}`}}>
          {/* <Heading>All Offers</Heading> */}
          <StyledOffersContainer>
            {offers.map((offer, key) => {
              return (
                <OfferCardVertical
                  openDetails={openModal}
                  key={key}
                  offer={offer}
                />
              );
            })}
            {offers.length > 0 && <div ref={last_element_ref} />}
          </StyledOffersContainer>
        </div>
      )}

      {selectedOffer && (
        <ClaimOfferModal
          quit={openModal}
          offer={selectedOffer}></ClaimOfferModal>
      )}
    </>
  );
});

OffersContainer.displayName = "OffersContainer";

export default React.memo(OffersContainer);
