// QuestsWorld.tsx

import React, {useEffect, useState} from "react";
import {
  QuestsWorld,
  QuestSantaTop,
  QuestsTitle,
  QuestsSantaTitle,
  QuestSantaText,
  QuestWorldBanner,
  QuestReward,
  WorldBannerDetails,
  WorldBannerText,
  QuestWorldList,
  QuestWorldItem,
  QuestWorldDetails,
  QuestWorldPoint,
  WorldTextTop,
  WorldTextBottom,
} from "../styles";
import assets from "../../assets";
import {API_SERVER} from "../../util/constants";

interface QuestWorldItemData {
  imageUrl: string;
  domain: string;
  title: string;
  description: string;
  xpPoints: number;
  unlockLevel: number;
}

interface LoyaltyPoints {
  points: number;
}

interface RewardInfo {
  loyaltyPoints: LoyaltyPoints;
}

interface CampaignNode {
  space: {
    name: string;
    thumbnail: string;
    links: string;
  };
  id: string;
  numberID: number;
  name: string;
  type: string;
  description: string;
  bannerUrl: string;
  info: string;
  seoImage: string;
  rewardName: string;
  distributionType: string;
  rewardInfo: RewardInfo;
}

interface CampaignsEdge {
  node: CampaignNode;
}

interface CampaignsData {
  campaigns: {
    edges: CampaignsEdge[];
  };
}

const QuestsWorldComponent: React.FC = () => {
  const worldItems: QuestWorldItemData[] = [
    {
      imageUrl: assets.quest_world_1,
      domain: "zetachain.com",
      title: "ZetaChain",
      description: "Discover the ZetaChain ecosystem",
      xpPoints: 100,
      unlockLevel: 2,
    },
    {
      imageUrl: assets.quest_world_2,
      domain: "optimism.io",
      title: "Optimism",
      description: "Build a world that benefits all, owned by none",
      xpPoints: 100,
      unlockLevel: 2,
    },
    {
      imageUrl: assets.quest_world_3,
      domain: "optimism.io",
      title: "Optimism",
      description: "Build a world that benefits all, owned by none",
      xpPoints: 100,
      unlockLevel: 2,
    },
  ];
  const [campaigns, setCampaigns] = useState<CampaignNode[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [bannerCampaign, setBannerCampaign] = useState<CampaignNode | null>(null);
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(`${API_SERVER}/proxy/graphigo/query`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `query GetRecommendedSpaces {
              campaigns(input: {first: 20, status: Active, listType: Trending, forAdmin: false, rewardTypes: LOYALTYPOINTS}) {
                edges {
                  node {
                    space {
                      name
                      thumbnail
                      links
                    }
                    id
                    numberID
                    name
                    type
                    description
                    bannerUrl
                    info
                    seoImage
                    thumbnail
                    rewardName
                    distributionType
                    rewardInfo {
                      loyaltyPoints {
                        points
                      }
                    }
                  }
                }
              }
            }`,
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        console.log("API response:", json);

        if (json && json.data && json.data.campaigns && json.data.campaigns.edges) {
          const uniqueSpaces = new Set();
          const filteredCampaigns = json.data.campaigns.edges
            .map((edge: CampaignsEdge) => {
              const node = edge.node;
              // Parse the JSON string in the links field
              node.space.links = JSON.parse(node.space.links).HomePage; // Store only the HomePage URL
              return node;
            })
            .filter((campaign: any) => {
              // Filter campaigns to include only those with a valid HomePage link
              return (
                campaign.space.thumbnail && campaign.space.links && !uniqueSpaces.has(campaign.space.name) && uniqueSpaces.add(campaign.space.name)
              );
            });
          let shuffledCampaigns = filteredCampaigns.sort(() => 0.5 - Math.random());
          if (shuffledCampaigns.length > 0) {
            setBannerCampaign(shuffledCampaigns[0]); // Set the first campaign as the banner
            shuffledCampaigns = shuffledCampaigns.slice(1); // Remove the banner campaign from the list
          }
          setCampaigns(shuffledCampaigns);
        } else {
          console.error("Unexpected API response structure:", json);
          setError("Unexpected API response structure");
        }
      } catch (e) {
        console.error("Error fetching campaigns:", e);
        setError("Failed to fetch campaigns");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, []);

  const redirectToCampaign = (campaignId: string | undefined) => {
    window.open(`https://galxe.com/space/campaign/${campaignId}`, "_blank");
  };

  return (
    <QuestsWorld>
      <QuestSantaTop>
        <div style={{maxWidth: "230px"}}>
          <QuestsTitle>Quests Around the World</QuestsTitle>
          <QuestsSantaTitle>Explore more Quest and start earning with Santa</QuestsSantaTitle>
        </div>
        {/* <div>
          <QuestSantaText>View All</QuestSantaText>
        </div> */}
      </QuestSantaTop>

      <QuestWorldBanner
        style={{backgroundImage: `url(${bannerCampaign?.space.thumbnail})`}}
        onClick={() => redirectToCampaign(bannerCampaign?.id)}>
        <div style={{display: "flex", justifyContent: "right", padding: "10px"}}>
          <QuestReward>Promoted</QuestReward>
        </div>
        <div style={{display: "flex", justifyContent: "bottom", padding: "10px"}}>
          <WorldBannerDetails>
            {/* <img
              src={bannerCampaign?.bannerUrl}
              alt=""
              width={18}
              height={18}
              style={{objectFit: "contain"}}
            /> */}
            <WorldBannerText>
              {bannerCampaign?.space.name} <span>{bannerCampaign?.name}</span>
            </WorldBannerText>
          </WorldBannerDetails>
        </div>
      </QuestWorldBanner>

      <QuestWorldList>
        {campaigns.map((campaign, index) => (
          <QuestWorldItem
            key={index}
            onClick={() => redirectToCampaign(campaign.id)}>
            <div style={{height: "inherit", position: "relative"}}>
              <img
                src={campaign.space.thumbnail}
                alt={`${campaign.space.name}`}
                style={{height: "inherit", borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px", width: "125px", objectFit: "cover"}}
              />
              <QuestWorldPoint>
                {/* <span>XP</span> */}
                {campaign.type}
              </QuestWorldPoint>
            </div>
            <QuestWorldDetails>
              <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                <WorldTextTop>
                  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${campaign.space.links}&sz=128`}
                      alt={`${campaign.space.links}`}
                      height={15}
                      width={15}
                    />
                    {campaign.space.name}
                  </div>
                </WorldTextTop>
                <WorldBannerText>{campaign.name}</WorldBannerText>
              </div>
              <WorldTextBottom>Distribution Type: {campaign.distributionType}</WorldTextBottom>
            </QuestWorldDetails>
          </QuestWorldItem>
        ))}
      </QuestWorldList>
    </QuestsWorld>
  );
};

export default QuestsWorldComponent;
