import React from "react";
import {QuestMain, QuestMainItem, QuestReward, QuestDesc, QuestMainAction} from "../styles";
import assets from "../../assets";
import {QuestsDefaultInstallProps} from "../types";

const QuestsDefaultInstall: React.FC<QuestsDefaultInstallProps> = ({handleSetDefaultBrowser, groups}) => {
  const defaultQuest = groups.flatMap(group => group.quests).find(quest => quest.uni === "default-1");

  const isDefaultCompleted = defaultQuest ? defaultQuest.done : false;

  const additionalQuests = [
    {
      uni: "other-device",
      description: "Earn More, install Santa on other device",
      reward: "100pts",
      imgSrc: assets.quest_main3,
    },
  ];
  return (
    <QuestMain>
      <QuestMainItem>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <QuestReward>
            {isDefaultCompleted && (
              <img
                src={assets.green_tick}
                alt="Green Tick"
                width={16}
                height={16}
              />
            )}
            {defaultQuest?.reward}pts
          </QuestReward>
          <QuestDesc>
            <em>
              Set <span style={{fontWeight: "600"}}>Santa</span> as default browsers
            </em>
          </QuestDesc>
        </div>
        <QuestMainAction
          onClick={handleSetDefaultBrowser}
          completed={isDefaultCompleted}>
          {isDefaultCompleted ? "Completed" : "Set as Default"}
          {!isDefaultCompleted && (
            <img
              src={assets.blue_download}
              alt="blue download arrow"
              width={10}
              height={10}
            />
          )}
        </QuestMainAction>
        <img
          src={assets.quest_main4}
          alt="quest main 4"
          width={76}
          height={68}
          style={{position: "absolute", bottom: "-3px", right: "-1px", zIndex: "1"}}
        />
      </QuestMainItem>
      {additionalQuests.map(quest => (
        <QuestMainItem key={quest.uni}>
          <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <QuestReward>{quest.reward}</QuestReward>
            <QuestDesc>
              <em>{quest.description}</em>
            </QuestDesc>
          </div>
          <QuestMainAction completed={false}>
            Install
            <img
              src={assets.blue_download}
              alt=""
              width={10}
              height={10}
            />
          </QuestMainAction>
          <img
            src={quest.imgSrc}
            alt={`${quest.uni}`}
            width={76}
            height={68}
            style={{position: "absolute", bottom: "-3px", right: "-1px", zIndex: "1"}}
          />
        </QuestMainItem>
      ))}
    </QuestMain>
  );
};

export default QuestsDefaultInstall;
